import React, { useState } from "react";
import DatePicker from "react-multi-date-picker";
import "../../styles/leave.css";
import { useLoaderData, useNavigate } from "react-router-dom";
import Resource from "../../api/resource";
import { Modal } from "react-bootstrap";

const ApplyLeave = () => {
  const [show, setShow] = useState(false);

  const [leaveDetails, setLeaveDetails] = useState({
    _id: "",
    from: "",
    to: "",
    message: "",
    for: "",
    created_by: "",
    organization: "",
    create_at: 0,
    state: "Created",
  });

  const navigate = useNavigate();

  const handleClose = () => {
    navigate(`/home/leaves/${show}`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeaveDetails({ ...leaveDetails, [name]: value });
  };

  const { empDetails } = useLoaderData();

  const handleSubmit = async (e) => {
    e.stopPropagation();
    let r = await Resource("requests").create(leaveDetails);
    setShow(true);
    console.log(r);
    setTimeout(() => {
      setShow(false);
      navigate("/home/leaves");
    }, 3000);
  };

  return (
    <main className="mainCardContainer">
      <div className="row">
        <div className="col-lg-12 bg-white bx-shadow border-radius5 p-4">
          <header className="d-flex justify-content-between position-relative ptb-20">
            <div className="card-header">
              <p className="card-title employeeTitle">Apply for Leave</p>
            </div>
          </header>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12 equal-form-space">
                    <div>
                      <label className="form-label" htmlFor="exampleInputname">
                        Leave for
                      </label>

                      <select
                        className="form-control"
                        onChange={handleChange}
                        name="for"
                      >
                        <option>Select Employee</option>
                        {(empDetails || []).map((employees) => {
                          return (
                            <option value={employees._id}>
                              {employees.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12 equal-form-space">
                    <label className="form-label" htmlFor="startDate">
                      Start Date
                    </label>
                    <br />
                    <input
                      placeholder="Start Date"
                      type="datetime-local"
                      id="startDate"
                      className="form-control"
                      required
                      name="from"
                      min={new Date().toISOString().slice(0, -8)}
                      value={
                        leaveDetails.from
                          ? leaveDetails.from.toISOString().slice(0, -8)
                          : ""
                      }
                      onChange={(e) => {
                        const date = new Date(e.target.value);
                        setLeaveDetails({ ...leaveDetails, from: date });
                      }}
                    />
                  </div>
                  <div className="col-lg-6 col-lg-12 equal-form-space">
                    <div>
                      <label className="form-label" htmlFor="toDate">
                        End Date
                      </label>
                      <br />
                      <input
                        placeholder="To Date"
                        type="datetime-local"
                        id="toDate"
                        required
                        className="form-control"
                        min={new Date().toISOString().slice(0, -8)}
                        name="to"
                        value={
                          leaveDetails.to
                            ? leaveDetails.to.toISOString().slice(0, -8)
                            : ""
                        }
                        onChange={(e) => {
                          const date = new Date(e.target.value);
                          setLeaveDetails({ ...leaveDetails, to: date });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12 equal-form-space">
                    <label className="form-label" htmlFor="exampleInputname">
                      Message
                    </label>

                    <textarea
                      placeholder="Message"
                      name="message"
                      className="form-control"
                      rows={9}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 d-flex justify-content-center mtb-20">
                <div>
                  <button className="cssbuttons-io-button">
                    Apply
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path
                          fill="white"
                          d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal
        size="sm"
        show={show ? true : false}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className="modal-suc"
      >
        <Modal.Body className="bg-none">
          <div className="cards">
            <article className="information card-pop-branch">
              <div className="branch-modal-head">
                <></>
                <i
                  className="bx bx-message-square-x bx-icon-branch-close"
                  onClick={handleClose}
                ></i>
              </div>
              <div>
                <h3 className="modal-title-branch">
                  Leave has been applied successfully.
                </h3>
              </div>
              <div className="modal-btns">
                <button className="btn-delete" onClick={handleClose}>
                  Close
                </button>
              </div>
              <dl className="details">
                <div></div>
              </dl>
            </article>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default ApplyLeave;
