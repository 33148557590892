import { redirect } from "react-router-dom";
import Resource from "../api/resource";

export const branchesLoader = async () => {
  try {
    let branches = await Resource("branches").getAll(
      undefined,
      undefined,
      "_id,name"
    );
    return { branches };
  } catch (e) {
    if (e.response.status === 401) {
      return redirect("/login");
    }
  }
  return redirect("/login");
};

export const leavesLoader = async () => {
  try {
    let empDetails = await Resource("users").getAll(
      undefined,
      undefined,
      "_id,name"
    );
    return { empDetails };
  } catch (e) {
    if (e.response.status === 401) {
      return redirect("/login");
    }
    return redirect("/login");
  }
};

export const branchLoader = async ({ params }) => {
  const branchId = params.branchId;

  try {
    let branch = await Resource("branches").get(branchId);
    return { branch };
  } catch (e) {
    if (e.response.status === 200) {
      return redirect("/login");
    }
  }
  return redirect("/login");
};

export const employeeEditLoader = async ({ params }) => {
  try {
    const userId = params.userId;
    let { branches } = await branchesLoader();

    let employee = await Resource("users").get(userId);
    return { branches, employee };
  } catch (e) {
    console.log(e);
    return redirect("/login");
  }
};
