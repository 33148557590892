import { commonRequest, METHODS } from "./common";

const Resource = (resource_name) => {
  const path = (id) => `/${resource_name}/` + id;

  return {
    getAll: async (skip = 0, limit = 100, filter = undefined) => {
      return await commonRequest(
        METHODS.get,
        path(`?skip=${skip}&limit=${limit}`) +
          (filter ? `&filter=${filter}` : ""),
        {}
      );
    },
    get: async (id) => {
      return await commonRequest(METHODS.get, path(id), {});
    },
    create: async (resource, invite) => {
      return await commonRequest(
        METHODS.post,
        path(invite === true ? "?invite=true" : ""),
        resource
      );
    },
    update: async (resource, invite=false) => {
      return await commonRequest(
        METHODS.put,
        path(resource._id + (invite? "?invite=true": "")),
        resource
      );
    },
    delete: async (id) => {
      return await commonRequest(METHODS.delete, path(id), {});
    },
  };
};

export default Resource;
