import axios from "axios";

const WEBAPI_SERBER = "http://localhost:26016";

export const scanFingerPrint = async () => {
  let response = await axios({
    url: `${WEBAPI_SERBER}/SGIFPCapture`,
    method: "POST",
    headers: {
      "Content-Type": "text/plain;charset=UTF-8",
    },
    data: "Timeout=10000&Quality=50&licstr=&templateFormat=ISO",
  });

  return response;
};

export const matchFingerPrint = async (template1, template2) => {
  let response = await axios({
    url: `${WEBAPI_SERBER}/SGIMatchScore`,
    method: "POST",
    headers: {
      "Content-Type": "text/plain;charset=UTF-8",
    },

    data: `template1=${template1}&template2=${template2}&templateFormat=ISO`,
  });

  return response;
};
