import React, { useState } from "react";
import check from "../../styles/check.module.css";
import { useNavigate, redirect, useLoaderData } from "react-router-dom";
import Resource from "../../api/resource";
import Modal from "react-bootstrap/Modal";
export const orgLoader = async () => {
  try {
    let orgData = await Resource("organizations").getAll(
      undefined,
      undefined,
      "_id,name"
    );
    return { orgData };
  } catch (error) {
    if (error.response.status == 401) {
      return redirect("/login");
    }
  }
};

export const Hrcreation = () => {
  const navigate = useNavigate();
  const [hrData, setHrData] = useState({
    designation: "Human Resource",
    role: "HR",
    branch: "",
    department:"Human Resource",
    photo: "",
    photoId: "",
  });

  const ImageComponent = ({ type }) => {
    const base64String = type;

    const imageSrc = base64String;

    return <img src={imageSrc} alt="Image" className="ImgBackend" />;
  };

  const [checked, setChecked] = React.useState(true);
  const [show, setShow] = useState("undefined");
  const { organizations } = useLoaderData();
  const handleClose = () => {
    setShow("undefined");
    navigate("/admin/hr");
  };
  return (
    <>
      <main className="main-container-org">
        <section className="row width-100">
          <div className="col-lg-3"></div>
          <div className="col-lg-8">
            {/* ============================================================= */}

            <div className="card bg-white bx-shadow">
              <div className="card-header">
                <h3 className="card-title m-0">HR Details</h3>
              </div>
              <div className="card-body">
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    try {
                      let r = await Resource("users").create(hrData, checked);
                      console.log(r);
                      setShow("Success");
                      setTimeout(() => {
                        setShow("undefined");
                        navigate("/admin/hr");
                      }, 2000);
                    } catch (e) {
                      
                      if (e.response.status === 401) {
                        navigate("/login");
                      }
                      else if (e.response.status === 500){
                        setShow("something");
                        
                      }
                    }
                  }}
                >
                  <div className="row">
                    <div className="col-lg-4 col-md-4 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname"
                        >
                          Name
                        </label>
                        <input
                          placeholder="name"
                          type="text"
                          id="exampleInputname"
                          className="form-control"
                          value={hrData.name}
                          onChange={(e) =>
                            setHrData({ ...hrData, name: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname"
                        >
                          Father's Name
                        </label>
                        <input
                          placeholder="name"
                          type="text"
                          id="exampleInputname"
                          className="form-control"
                          value={hrData.fatherName}
                          onChange={(e) =>
                            setHrData({ ...hrData, fatherName: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname"
                        >
                          Qualification
                        </label>
                        <input
                          placeholder="name"
                          type="text"
                          id="exampleInputname"
                          className="form-control"
                          value={hrData.qualification}
                          onChange={(e) =>
                            setHrData({ ...hrData, qualification: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname"
                        >
                          Date Of Birth
                        </label>
                        <input
                          placeholder="name"
                          type="date"
                          id="exampleInputname"
                          className="form-control"
                          value={hrData.dob}
                          onChange={(e) =>
                            setHrData({ ...hrData, dob: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname"
                        >
                          Gender
                        </label>
                        <select
                          name="gender"
                          value={hrData.gender}
                          onChange={(e) =>
                            setHrData({ ...hrData, gender: e.target.value })
                          }
                          id="exampleInputname"
                          className="form-control"
                        >
                          <option value={0}>Select gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Others">Others</option>
                        </select>
                        {/* <input
                    placeholder="Name"
                    type="text"
                    name="name"
                    value={emp.name}
                    onChange={update}
                    id="exampleInputname"
                    className="form-control"
                  /> */}
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname1"
                        >
                          Email
                        </label>
                        <input
                          placeholder="email"
                          type="email"
                          id="exampleInputname1"
                          className="form-control"
                          value={hrData.email}
                          onChange={(e) =>
                            setHrData({ ...hrData, email: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname1"
                        >
                          Aadhar Number
                        </label>
                        <input
                          placeholder="Aadhar Number"
                          type="text"
                          onChange={(e) =>
                            setHrData({ ...hrData, aadhar: e.target.value })
                          }
                          value={hrData.aadhar}
                          maxLength={16}
                          name="aadhar"
                          id="exampleInputname1"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname1"
                        >
                          Pan Number
                        </label>
                        <input
                          placeholder="Pan number"
                          type="text"
                          onChange={(e) =>
                            setHrData({ ...hrData, pan: e.target.value })
                          }
                          value={hrData.pan}
                          maxLength={10}
                          name="pan"
                          id="exampleInputname1"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname1"
                        >
                          Upload Photo
                        </label>
                        <input
                          placeholder="Employee photo"
                          type="file"
                          name="photo"
                          id="exampleInputname1"
                          className="form-control"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            const reader = new FileReader();
                            reader.onloadend = () => {
                              const base64Image = reader.result;
                              setHrData({ ...hrData, photo: base64Image });
                            };

                            if (file) {
                              reader.readAsDataURL(file);
                            }
                          }}
                        />
                        {hrData._id ? (
                          <>
                            <ImageComponent type={hrData.photo} />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname1"
                        >
                          ID Proof Upload
                        </label>
                        <input
                          placeholder="Id Proof Upload"
                          type="file"
                          name="photoId"
                          id="exampleInputname1"
                          className="form-control"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            const reader = new FileReader();
                            reader.onloadend = () => {
                              const base64Image = reader.result;
                              setHrData({ ...hrData, photoId: base64Image });
                            };

                            if (file) {
                              reader.readAsDataURL(file);
                            }
                          }}
                        />
                        {hrData._id ? (
                          <>
                            <ImageComponent type={hrData.photoId} />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname1"
                        >
                          Employee Id
                        </label>
                        <input
                          placeholder="employee Id"
                          type="text"
                          onChange={(e) =>
                            setHrData({ ...hrData, empNo: e.target.value })
                          }
                          value={hrData.empNo}
                          name="empNo"
                          id="exampleInputname1"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-lg-4 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname"
                        >
                          Number
                        </label>
                        <input
                          placeholder="number"
                          type="text"
                          id="exampleInputname"
                          className="form-control"
                          value={hrData.mobile}
                          onChange={(e) =>
                            setHrData({ ...hrData, mobile: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname"
                        >
                          Date of joining
                        </label>
                        <input
                          placeholder="Logo Url"
                          type="date"
                          id="exampleInputname"
                          className="form-control"
                          value={hrData.joiningDate}
                          onChange={(e) =>
                            setHrData({
                              ...hrData,
                              joiningDate: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname"
                        >
                          Select Organization
                        </label>

                        <select
                          name=""
                          id=""
                          className="form-control"
                          onChange={(e) =>
                            setHrData({
                              ...hrData,
                              organization: e.target.value,
                            })
                          }
                        >
                          <option disabled>Select</option>
                          {organizations ? (
                            organizations.map((org) => {
                              return (
                                <option value={org._id}>{org.name}</option>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </select>
                      </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-4 equal-form-space">
                      <aside className="d-flex justify-content-between choose-color">
                        <div>
                          <p>Invite</p>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            className={check.tog}
                            defaultChecked={checked}
                            onChange={() => setChecked(!checked)}
                          />
                        </div>
                      </aside>
                    </div> */}
                    <div className="col-lg-4 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname"
                        >
                          Designation
                        </label>
                        <input
                          placeholder="designation"
                          type="text"
                          id="exampleInputname"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname"
                        >
                          Department
                        </label>
                        <input
                          placeholder="Human resource"
                          type="text"
                          value="Human Resource"
                          id="exampleInputname"
                          className="form-control"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 d-flex justify-content-center mtb-20">
                      <div>
                        <button className="cssbuttons-io-button">
                          Submit
                          <div className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path
                                fill="white"
                                d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                              ></path>
                            </svg>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
        </section>
      </main>
      {show === "Success" ? <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className="modal-suc"
      >
        <Modal.Body className="bg-none">
          <div className="cards">
            <article className="information card-pop-branch">
              <div className="branch-modal-head">
                <></>
                <i
                  className="bx bx-message-square-x bx-icon-branch-close"
                  onClick={handleClose}
                ></i>
              </div>
              <div>
                <h3 className="modal-title-branch">
                  Hr has been Created Successfully.
                </h3>
              </div>
              <div className="modal-btns">
                <button className="btn-delete" onClick={() => setShow(false)}>
                  Close
                </button>
                {/* <button
                    className="btn-delete"
                    id=""
                    // onClick={deleteModalHandler}
                  >
                    Delete
                  </button> */}
              </div>
              <dl className="details">
                <div></div>
              </dl>
            </article>
          </div>
        </Modal.Body>
      </Modal>
      
      
      </> : show === "something" ? <>
      
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className="modal-suc"
      >
        <Modal.Body className="bg-none">
          <div className="cards">
            <article className="information card-pop-branch">
              <div className="branch-modal-head">
                <></>
                <i
                  className="bx bx-message-square-x bx-icon-branch-close"
                  onClick={handleClose}
                ></i>
              </div>
              <div>
                <h3 className="modal-title-branch">
                 Something Went Wrong Try Again.
                </h3>
              </div>
              <div className="modal-btns">
                <button className="btn-delete" onClick={() => setShow(false)}>
                  Close
                </button>
                 
              </div>
              <dl className="details">
                <div></div>
              </dl>
            </article>
          </div>
        </Modal.Body>
      </Modal>
      </> : show === "undefined" ? <></> : undefined}

      
    </>
  );
};

export default Hrcreation;
