import { useState } from "react";
import { request, METHODS, setAccessToken } from "../../api/common";
import {
  Link,
  useNavigate,
  redirect,
  useParams,
  useLoaderData,
} from "react-router-dom";
import Resource from "../../api/resource";
import { Modal } from "react-bootstrap";

export const Branchdetails = () => {
  const [show, setShow] = useState(false);

  const { branchId } = useParams();
  const data = useLoaderData();
  // console.log(data);

  const navigate = useNavigate();
  const [branchData, setBranchData] = useState(
    data && data.branch
      ? data.branch
      : {
          name: "",
          city: "",
          state: "",
          number: "",
        }
  );

  const handleChange = (e) => {
    e.preventDefault();
    setBranchData({ ...branchData, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setShow(false);
    navigate("/home/branches");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      branchData.organization = "";
      let resource = Resource("branches");
      let response = branchId
        ? await resource.update(branchData)
        : await resource.create(branchData);
      setShow(true);
      setTimeout(() => {
        setShow(false);
        navigate("/home/branches");
      }, 2000);
      console.log(response);
    } catch (e) {
      if (e) {
        // Invalid User Name or Password
        console.log(e.response.status);
      }
    }
  };
  return (
    <>
      <main className="mainCardContainer">
        <div className="row">
          <div className="col-lg-12 bg-white bx-shadow border-radius5">
            <header className="d-flex justify-content-between position-relative ptb-20">
              <div className="card-header">
                {branchId ? (
                  <p className="card-title employeeTitle">Edit Branch</p>
                ) : (
                  <p className="card-title employeeTitle">Create Branch</p>
                )}
              </div>
            </header>
            <form onSubmit={handleSubmit} className="row">
              <div className="col-lg-6 col-md-6 equal-form-space">
                <div>
                  <label className="form-label" htmlFor="exampleInputname">
                    Branch Name
                  </label>
                  <input
                    placeholder="Branch Name"
                    type="text"
                    id="exampleInputname"
                    className="form-control"
                    name="name"
                    value={branchData.name}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-6 equal-form-space">
                <div>
                  <label className="form-label" htmlFor="exampleInputname">
                    City
                  </label>
                  <input
                    placeholder="City"
                    type="text"
                    id="exampleInputname"
                    className="form-control"
                    name="city"
                    value={branchData.city}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-lg-6 equal-form-space">
                <div>
                  <label className="form-label" htmlFor="exampleInputname">
                    State
                  </label>
                  <input
                    placeholder="Andhra Pradesh"
                    type="text"
                    id="exampleInputname"
                    className="form-control"
                    name="state"
                    value={branchData.state}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-6 equal-form-space">
                <div>
                  <label className="form-label" htmlFor="exampleInputname">
                    Mobile Number
                  </label>
                  <input
                    placeholder="Branch Number"
                    type="number"
                    id="exampleInputname"
                    className="form-control"
                    name="number"
                    value={branchData.number}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-lg-12 d-flex justify-content-center mtb-20">
                <div>
                  <button className="cssbuttons-io-button" type="submit">
                    {branchId ? "Update" : "Create"}
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path
                          fill="white"
                          d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Modal
          size="sm"
          show={show}
          onHide={handleClose}
          aria-labelledby="example-modal-sizes-title-sm"
          className="modal-suc"
        >
          <Modal.Body className="bg-none">
            <div className="cards">
              <article className="information card-pop-branch">
                <div className="branch-modal-head">
                  <></>
                  <i
                    className="bx bx-message-square-x bx-icon-branch-close"
                    onClick={handleClose}
                  ></i>
                </div>
                <div>
                  <h3 className="modal-title-branch">
                    {`Your Branch has been successfully ${
                      branchId ? "updated" : "created"
                    }.`}{" "}
                  </h3>
                </div>
                <div className="modal-btns">
                  <button className="btn-delete" onClick={() => setShow(false)}>
                    Close
                  </button>
                  {/* <button
                    className="btn-delete"
                    id=""
                    // onClick={deleteModalHandler}
                  >
                    Delete
                  </button> */}
                </div>
                <dl className="details">
                  <div></div>
                </dl>
              </article>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    </>
  );
};

export default Branchdetails;

export const BranchLoader = async () => {
  try {
    let r = await Resource("branches").getAll();
    return r;
  } catch (e) {
    return redirect("/banches");
  }
};
