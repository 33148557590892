import React, { useContext } from "react";

import Sidebar from "../sidebar/sider";
import { Outlet, redirect, useRouteLoaderData } from "react-router-dom";
import Resource from "../../api/resource";
import { async } from "q";
import { Loading, useLoading } from "../util/loading";

export const Admindashboard = () => {
  const loading = useLoading();

  // console.log(data)
  return (
    <>
      <Sidebar />
      <div className="main-content side-content pt-0">{<Outlet />}</div>
    </>
  );
};
export default Admindashboard;

export const MainLoader = async () => {
  try {
    let r = await Resource("analytics").get("");

    return { analytics: r[0], user: r[1] };
  } catch (e) {
    return redirect("/login");
  }
};

export const RootLoader = async () => {
  try {
    let r = await Resource("users").get("me");
    if (r.role === "superAdmin") {
      return redirect("/admin");
    } else {
      return redirect("/home");
    }
  } catch (e) {
    console.log(e);
    return redirect("/login");
  }
};
