import React from "react";
import error from "../../assets/errorimg.png";
import "./pageNotfound.css";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="wrapper_fnf ">
        <div className="landing-page_fnf">
          <div className="icon__download">
            <div className="d-flex justify-content-center">
              <img src={error} alt="" srcset="" className="w-1000" />
            </div>
          </div>

          {/* <h1> 404 Error.</h1> */}
          <p className="mb-4"> We can't find the page you're looking for.</p>
          {/* <Link to="/home"> */}
          <button onClick={()=> navigate(-1)}>Back to home</button>
          {/* </Link> */}
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
