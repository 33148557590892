import axios from "axios";

import { redirect } from "react-router-dom";

const ENV_URL = process.env.REACT_APP_BASE_URL;
export const DOMAIN = "api.attendx.in/"; //ENV_URL;
export const BASE_URL = "https://" + DOMAIN;

const ACCESS_TOKEN_KEY = "accessToken";

export function accessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function setAccessToken(token) {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
}
export function clearAccessToken(token) {
  localStorage.clear();
}

export function universalError(err) {
  const status_code = err.response.status;
  if (status_code === 401) {
    return redirect("/login");
  }
}

export const METHODS = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};

export const request = async (method, v, path, body) => {
  const response = await axios({
    url: BASE_URL + v + path,
    method: method,
    headers: {
      Authorization: "Bearer " + accessToken(),
    },
    data: body,
  });

  return response.data;
};

export const commonRequest = async (method, path, body) => {
  return await request(method, "v1", path, body);
};

export const commonRequestV1 = (method, path, body, onSuccess, onFailure) => {
  return request(method, "v1", path, body, onSuccess, onFailure);
};
