import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { request, METHODS, setAccessToken } from "../../api/common";
// import "../../styles/global.css";
import notfound from "../../assets/notfound.svg";
import Logo from "../../assets/logoattendex.png";
import "../../styles/login.css";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ButtonLoading } from "../util/loading";

export const Login = () => {
  const [invalidPwd, setInvalidPwd] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const [forgot, setForgot] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    e.preventDefault();
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const loginSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let response = await request(METHODS.post, "v1", "/token", {
        username: loginData.email,
        password: loginData.password,
      });
      const token = response.token;
      const role = response.role;
      setAccessToken(token);

      navigate(role === "superAdmin" ? "/admin" : "/home");

      console.log(response);
    } catch (e) {
      if (e.response.status === 401) {
        // Invalid User Name or Password
        setInvalidPwd(true);
        setSmShow(true);
        console.log("401 reponse get invalid user name or password");
      }
    }
    setLoading(false);
  };

  return (
    <>
      <div className="login-content d-flex">
        <div className="cardLogin">
          {/* <div className="tools">
            <div className="circle">
              <span className="red box"></span>
            </div>
            <div className="circle">
              <span className="yellow box"></span>
            </div>
            <div className="circle">
              <span className="greenLOgin box"></span>
            </div>
          </div> */}
          <div className="card__content">
            <div className="screen-1">
              <img src={Logo} alt="" srcset="" className="w-350" />
            </div>
            <div className="loginTitle">
              <h3>Login to your Account</h3>
              <p>
                Get started with our Dashboard and enjoy the
                experience.
              </p>
            </div>
            <section className="formField">
              <form onSubmit={loginSubmit}>
                <main>
                  <div className="input-groupLogin">
                    <input
                      reuired=""
                      type="text"
                      name="email"
                      autocomplete="off"
                      className="inputLogin"
                      onChange={handleChange}
                    />
                    <label className="user-label">Employee Id</label>
                  </div>
                  <div className="input-groupLogin">
                    <input
                      required=""
                      type="Password"
                      autocomplete="off"
                      className="inputLogin"
                      name="password"
                      onChange={handleChange}
                    />
                    <label className="user-label">Password</label>
                    {smShow ? (
                      <>
                        <p className="LogoColorRed mt-2">
                          <i className="bx bx-shield-x"></i> Please Check Your
                          Id and Password
                        </p>
                      </>
                    ) : undefined}
                  </div>

                  <div className="btnPart">
                    <button type="submit" className="LoginBtn">
                      {loading ? <ButtonLoading /> : "Login"}
                    </button>
                  </div>
                  {/* <div className="forgotPass">
                    <Link to="reset">Forgot Password ?</Link>
                  </div> */}
                </main>
              </form>
            </section>
          </div>
        </div>
      </div>
      {/* <Modal
                  size="sm"
                  show={smShow}
                  onHide={() => setSmShow(false)}
                  aria-labelledby="example-modal-sizes-title-sm"
                  className="modal-suc"
                >
                  <Modal.Body className="bg-none">
                    <div className="cards">
                      <article className="information card-pop">
                        <h2 className="title">
                          Hi Welcome to SB Ventures Biometrick
                        </h2>
                        <p className="LogoColorRed">
                        <i className='bx bx-shield-x'></i> Please Check Your Email and Password
                        </p>
                        <dl className="details">
                          <div>
                            <img
                              src={notfound}
                              alt=""
                              srcset=""
                              className="notfoundLogin"
                            />
                          </div>
                        </dl>
                      </article>
                    </div>
                  </Modal.Body>
                </Modal> */}
    </>
  );
};
export default Login;
