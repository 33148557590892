export const Footer = () => {
return (
    <>
     <div className="clearfix" />
      <footer className="app-footer ptb-16 bg-white_x mlx-0">
        <p className="caption base-3 m-0 pb-0">
          Powered by&nbsp;:&nbsp;
          <a
            href="#"
            target="_blank"
            className="primary-blue"
          >
            Techsaadhu
          </a>
          &nbsp;|&nbsp;
          <a
            href="https://darwinbox.com/privacy-policy"
            target="_blank"
            className="primary-blue"
          >
            HR Solutions
          </a>
        </p>
      </footer>
    </>
)
}
export default Footer;