import { useContext, useState } from "react";
import { DarkModeContext } from "../context/index";
import org from "../../assets/org_structure_icon.svg";
import attendence from "../../assets/attendance.png";
import employee from "../../assets/emp.png";
import employgroup from "../../assets/employees.png";
import greentick from "../../assets/green-tick.svg";
import neworg from "../../assets/add_org.png";
import dashboard from "../../assets/dashboard.png";
import Logo from "../../assets/logowhite.png";
import task from "../../assets/task.png";
import { Link, useNavigate } from "react-router-dom";
import { clearAccessToken } from "../../api/common";
export const Header = () => {
  const [showbar, SetshowBar] = useState(true);
  // const handeToggle = event =>{
  //   SetshowBar(current => !current);
  // }
  const navigate = useNavigate();
  return (
    <>
      <div className="topbar">
        <div className="portlet" id="yw0">
          <div className="portlet-content">
            <div
              className="dashboard-top"
              style={{ background: "#000000" }}
              id="dasboard-bigheader"
            >
              <header className="top-header row">
                <div className="col-md-3">
                  <div className="">
                    <Link to="/admin">
                      <img src={Logo} alt="SB Ventures" />
                    </Link>
                  </div>
                </div>

                <div className="col-md-9 desktopDisplay alignAdminProfile">
                  <ul className="list-inline display-inline-flex align-items-center">
                    <div className="profile-container">
                      <div className="profile-image">SP</div>
                      <div className="profile-options">
                        {/* <a href="#" className="view-profile">View Profile</a> */}
                        <a
                          className="logout"
                          onClick={(e) => {
                            e.preventDefault();
                            clearAccessToken();
                            navigate("/login");
                          }}
                        >
                          <i className="bx bx-power-off"></i>&nbsp;Logout
                        </a>
                      </div>
                    </div>
                  </ul>
                </div>
                <div className="clearfix" />
              </header>
              <div className="clearfix" />
            </div>
            {/* SIDE BAR MENU */}

            <div
              id="full-wrapper-dashboard-header"
              className={showbar ? "d-block" : "d-none"}
            >
              <div className="pull-left notice-dashboard-wrapper db-customscrollbar">
                <div className="col-xs-12 menu-db-drop ptb-16 p-0">
                  <div className="col-xs-12 plr-12 d-flex flex-wrap">
                    <div className="single-menu-item">
                      <Link to="/admin">
                        <img src={dashboard} />
                        <p>Dashboard</p>
                      </Link>
                    </div>
                    <div className="single-menu-item">
                      <Link to="organization/new">
                        <img src={neworg} alt="organization" />

                        <p>Add Org</p>
                      </Link>
                    </div>
                    {/* <div className="single-menu-item">
                      <Link to="/admin">
                        <img src={employee} alt="employees" />
                        <p>SB Force</p>
                      </Link>
                    </div> */}
                    <div className="single-menu-item">
                      <Link to="organization">
                        <img src={org} alt="neworgnization" />
                        <p>View Org</p>
                      </Link>
                    </div>
                    <div className="single-menu-item">
                      <Link to="hr">
                        <img src={employgroup} alt="biometric" />
                        <p>Add HR</p>
                      </Link>
                    </div>
                    {/* <div className="single-menu-item">
                      <Link to="/admin">
                        <img src={attendence} alt="attendence" />
                        <p>Attendance</p>
                      </Link>
                    </div> */}
                    {/* <div className="single-menu-item">
                      <Link to="/admin">
                        <img src={task} alt="biometrick" />
                        <p>Test</p>
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* SIDE BAR MENU */}
            {/* clock in and clock out modal */}
            <div
              id="clokInClockout"
              className="modal fade db-customized-modal"
              role="dialog"
            >
              <div className="modal-dialog modal-sm">
                {/* Modal content*/}
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <df className="db-Close fill-circle" />
                    </button>
                    <h4 className="title-1 font-16" />
                  </div>
                  <div className="modal-body">
                    <div className="form-group">
                      <label className="db-label">Location</label>
                      <select
                        name=""
                        id=""
                        className="form-control dbSelect location_type"
                      >
                        <option value={2} selected="">
                          Home
                        </option>
                        <option value={1}>Office</option>
                      </select>
                    </div>
                    <div className="text-right">
                      <button
                        type="button"
                        className="btn db-btn btn-primary submit_clockInOut"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* notification JS */}
            {/* notification JS */}
            {/* APPROVALS JS */}
            {/* INTRO JS GUIDED TOUR*/}
            {/* INTRO JS GUIDED TOUR*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
