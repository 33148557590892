import DataTable from "react-data-table-component";
import FixedHeaderStory from "react-data-table-component";
import React, { useMemo, useState } from "react";
import custom from "../../styles/check.module.css";
import {
  Link,
  redirect,
  useLoaderData,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom";
import { matchSorter } from "match-sorter";
import Resource from "../../api/resource";
import { Button, Modal } from "react-bootstrap";
import { METHODS, commonRequest } from "../../api/common";
import { useRef } from "react";

export const Hrdetails = () => {

  const [delId, setDelId] = useState("");
  const [show, setShow] = useState(false);
  const [delShow, setDelShow] = useState(false);
  const { Hrs, orgs } = useLoaderData();
  const navigate = useNavigate();
  const handleShow = (id) => {
    setDelId(id);
    // setShow(true);
    setDelShow(true);
  };
  const selectedUser = useRef();

  const columns = [
    { name: "Hr Id", selector: "empNo" },
    { name: "Name", selector: "name" },
    { name: "Organization", selector: (hr) => orgs.find(o => o._id === hr.organization )?.name },
    {
      name: "Action",
      selector: (row) => (
          <>
            <div className="d-flex actionIcon">
              <button
                  type="submit"
                  className="btnEmply brdblue"
                  onClick={() => {
                    selectedUser.current = row._id;
                    setShow(true);
                  }}
              >
                <i className="bx bxs-key"></i>
              </button>

              <button
                  type="submit"
                  className="btnEmply brdRed"
                  id={row._id}
                  onClick={() => handleShow(row._id)}
              >
                <i className="bx bxs-trash"></i>
              </button>
            </div>
          </>
      ),
    }
  ];


  const [filterText, setFilterText] = React.useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const filterItems = matchSorter(Hrs, filterText, {
    keys: ["id", "name", "email", "empNo", "designation", "department","organization"],
  });

  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const deleteModalHandler = async () => {
    try {
      let response = await Resource("users").delete(delId);
      setShow(false);
      // navigate("/home/hrs");
      console.log(response);
    } catch (e) {
      if (e) {
        console.log(e.response.status);
      }
    }
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password === confirmPassword) {
      try {
        let r = await commonRequest(
          METHODS.put,
          `/users/${selectedUser.current}/password`,
          {
            password: password,
          }
        );
      } catch (e) {
        console.error(e);
      }
      setPassword("");
      setConfirmPassword("");
      setError("");
      setShow(false);
    } else {
      // Passwords mismatch, display error
      setError("Passwords Mismatched");
    }
  };
  return (
    <>
      <Modal
        size="sm"
        // show={show}
        // onHide={() => setShow(false)}
        show={delShow}
        onHide={() => {
          setDelShow(false);
        }}
        aria-labelledby="example-modal-sizes-title-sm"
        className="modal-suc"
      >
        <Modal.Body className="bg-none">
          <div className="cards">
            <article className="information card-pop-branch">
              <div className="branch-modal-head">
                <></>
                <i
                  className="bx bx-message-square-x bx-icon-branch-close"
                  onClick={() => setDelShow(false)}
                ></i>
              </div>
              <div>
                <h3 className="modal-title-branch">
                  Are you sure you want to delete this Hr ?
                </h3>
              </div>
              <div className="modal-btns">
                <button
                  className="btn-cancel"
                  onClick={() => setDelShow(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn-delete"
                  id=""
                  onClick={deleteModalHandler}
                >
                  Delete
                </button>
              </div>
              <dl className="details">
                <div></div>
              </dl>
            </article>
          </div>
        </Modal.Body>
      </Modal>
      <main className="mainCardContainer">
        <div className="row main-view">
          <div className="col-lg-2"></div>
          <div className="col-lg-9 bg-white bx-shadow border-radius5">
           
            <header className="d-flex justify-content-end position-relative pt-20">
              {/* <div className="card-header">
                <p className="card-title HrTitle">Hr's Detail's</p>
              </div> */}
              <aside>
                <Link to="new">
                  <div className={custom.button}>
                    <div className={custom.buttonwrapper}>
                      <div className={custom.text}>Add Hr</div>
                      <span className={custom.icon}>
                        <i className="bx bxs-user-plus"></i>
                      </span>
                    </div>
                  </div>
                </Link>
              </aside>
            </header>
            <section className="reactDatatable position-relative">
              <FixedHeaderStory
                title="Hr Details"
                fixedHeader
                fixedHeaderScrollHeight="500px"
                columns={columns}
                data={filterItems}
                pagination
                paginationPerPage={7}
                paginationResetDefaultPage={resetPaginationToggle}
                persistTableHead
              />
              <div className="search-box">
                <input
                  className="search-input"
                  type="search"
                  placeholder="Search something.."
                  onChange={(e) => setFilterText(e.target.value)}
                />
                <button className="search-btn">
                  <i className="bx bx-search"></i>
                </button>
              </div>
            </section>
           
           
          </div>
          <div className="col-lg-1"></div>
        </div>
        <Modal
          size="sm"
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
          className="modal-suc"
        >
          <Modal.Body className="bg-none">
            <form onSubmit={handleSubmit}>
              <div className="cards">
                <article className="information change-pwd-pop">
                  <div className="branch-modal-head">
                    <></>
                    <i
                      className="bx bx-message-square-x bx-icon-branch-close"
                      onClick={() => setShow(false)}
                    ></i>
                  </div>
                  <div className="row">
                    <h5 className="text-center">Change Password</h5>
                    <div className="col-lg-12 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname1"
                        >
                          Password
                        </label>
                        <input
                          placeholder="Enter Password"
                          type="password"
                          value={password}
                          onChange={handlePasswordChange}
                          name="password"
                          id="exampleInputname1"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname1"
                        >
                          Confirm Password
                        </label>
                        <input
                          placeholder="Re-enter Password"
                          type="password"
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                          name="confPwd"
                          id="exampleIputname1"
                          className="form-control"
                        />
                      </div>
                    </div>
                    {error && <p className="pwd-err">{error}</p>}
                  </div>
                  <div className="modal-btns">
                    {/* <button className="btn-cancel" onClick={() => setShow(false)}>
                    Cancel
                  </button> */}
                    <button
                      className="btn-delete"
                      id=""
                      // onClick={deleteModalHandler}
                    >
                      Update Password
                    </button>
                  </div>
                </article>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </main>
    </>
  );
};

export const HRsLoader = async () => {
  try {
    let Hrs = await Resource("users").get("hr");
    let orgs = await Resource("organizations").getAll(0, 10000, "name");

    return { Hrs, orgs};
  } catch (e) {
    return redirect("/login");
  }
};

export default Hrdetails;
