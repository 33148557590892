import { useNavigation } from "react-router-dom";
import { ReactComponent as Svg } from "../../assets/loading.svg";

export const useLoading = () =>{
    const navigation = useNavigation()
    return navigation.state === "loading"
}


export const Loading = () => {
    return (
      <>
        <section className="loader_align">
          <div className="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </section>
      </>
    );
  };

export const ButtonLoading = () =>{
    return <Svg className="spinner"/>
}