import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/leave.css";
import DataTable from "react-data-table-component";
import { requestStates } from "./viewLeave";
import { useEffect } from "react";
import Resource from "../../api/resource";
import { METHODS, commonRequest } from "../../api/common";

const Leave = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState("Created");

  const [leaves, setLeaves] = useState([]);

  const columns = [
    {
      name: "ID",
      selector: (row) => <Link to={row._id}>{row._id}</Link>,
    },
    {
      name: "From",
      selector: (row) => (
        <>
          {new Date(row.from).toLocaleString(undefined, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })}
        </>
      ),
    },
    {
      name: "To",
      selector: (row) => (
        <>
          {new Date(row.to).toLocaleString(undefined, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })}
        </>
      ),
    },
    {
      name: "No of Days",
      selector: (row) => {
        const date1 = new Date(row.from);
        const date2 = new Date(row.to);
        const diffInMs = date2.getTime() - date1.getTime();
        return Math.floor(diffInMs / (1000 * 60 * 60 * 24)) + 1;
      },
    },
  ];

  useEffect(() => {
    const g = async () => {
      try {
        const leaves = await commonRequest(
          METHODS.get,
          `/requests/?state=${filter}`
        );
        setLeaves(leaves);
      } catch (e) {
        if (e.response.status === 401) {
          return navigate("/login");
        }
      }
    };

    g();
  }, [filter]);

  return (
    <main className="mainCardContainer">
      <div className="row">
        <div className="col-lg-12 bg-white bx-shadow border-radius5">
          <div className="row">
            <div className="col-lg-4 leave-head-item">
              <h4>Leave History</h4>
</div>
<div className="col-lg-4 col-xs-6 mb-4">
              <select
                className="cssbuttons-io-button"
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              >
                <option selected disabled>
                  Leave Status
                </option>
                {requestStates.map((state) => (
                  <option value={state} id={state} selected={state === filter}>
                    {state}
                  </option>
                ))}
              </select>
              </div>
              <div className="col-lg-4 col-xs-6 ml-">
              <button
                className="cssbuttons-io-button"
                onClick={() => {
                  navigate("/home/leaves/new");
                }}
              >
                Apply for Leave
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path
                      fill="white"
                      d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                    ></path>
                  </svg>
                </div>
              </button>
            </div>
          </div>
          <div>
            <DataTable
              columns={columns}
              data={leaves}
              pagination
              paginationPerPage={8}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Leave;
