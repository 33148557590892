import "../../styles/branch.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import neworg from "../../assets/add_org.png";
import noData from "../../assets/No data-pana.svg";

import { useLoaderData } from "react-router";
import { Button, Modal } from "react-bootstrap";
import Resource from "../../api/resource";

export const Branch = () => {
  const [delId, setDelId] = useState("");

  const [show, setShow] = useState(false);
  const handleShow = (e) => {
    const GetId = e.target.id;
    setDelId(GetId);
    setShow(true);
  };
  const navigate = useNavigate();

  const { branches } = useLoaderData();

  const deleteModalHandler = async () => {
    try {
      let response = await Resource("branches").delete(delId);
      setShow(false);
      navigate("/home/branches");
      console.log(response);
    } catch (e) {
      if (e) {
        console.log(e.response.status);
      }
    }
  };

  return (
    <>
      <div className="container mbpt5rem">
        <div className="row">
          <div className="col-lg-12">
            <div className="branch-head-item">
              <h4>Our Branches</h4>

              <button
                className="cssbuttons-io-button"
                onClick={() => {
                  navigate("/home/branches/new");
                }}
              >
                Add Branch
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path
                      fill="white"
                      d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                    ></path>
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>

        <Modal
          size="sm"
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
          className="modal-suc"
        >
          <Modal.Body className="bg-none">
            <div className="cards">
              <article className="information card-pop-branch">
                <div className="branch-modal-head">
                  <></>
                  <i
                    className="bx bx-message-square-x bx-icon-branch-close"
                    onClick={() => setShow(false)}
                  ></i>
                </div>
                <div>
                  <h3 className="modal-title-branch">
                    Are you sure you want to delete this branch ?
                  </h3>
                </div>
                <div className="modal-btns">
                  <button className="btn-cancel" onClick={() => setShow(false)}>
                    Cancel
                  </button>
                  <button
                    className="btn-delete"
                    id=""
                    onClick={deleteModalHandler}
                  >
                    Delete
                  </button>
                </div>
                <dl className="details">
                  <div></div>
                </dl>
              </article>
            </div>
          </Modal.Body>
        </Modal>

        {branches && branches.length > 0 ? (
          <div>
            <section className="mbBranch">
              {branches.reverse().map((branchData, idx) => {
                return (
                  <div
                    className="col-md-2 col-sm-2 inBlock"
                    key={branches?._id}
                  >
                    <div className="cardBranch">
                      <div className="content">
                        <div className="front">
                          <div className="branch-menu-item">
                            <Link to="#">
                              <div className="module-icon-wrapper">
                                <img src={neworg} alt="organization" />
                              </div>
                              <p>{branchData?.name}</p>
                            </Link>
                          </div>
                        </div>
                        <div className="back from-left">
                          <div className="edit-del-icons">
                            <i
                              className="bx bx-edit bg-tut"
                              onClick={() =>
                                navigate(
                                  `/home/branches/${branchData._id}/edit`
                                )
                              }
                            ></i>
                            <i
                              className="bx bx-message-square-x bg-tut"
                              id={branchData._id}
                              onClick={handleShow}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </section>
          </div>
        ) : (
          <>
            <div className="no-data">
              <div>
                <img src={noData} alt="nodata" />
                <h3>No Branches Found</h3>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Branch;
