import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { Loading, useLoading } from "../../components/util/loading";

export const Organizationhome = () => {
  const loading = useLoading()
  return (
    <>
      {loading?  <Loading/>:<Outlet />}
    </>
  );
};
export default Organizationhome;
