import DataTable from "react-data-table-component";
import FixedHeaderStory from "react-data-table-component";
import Resource from "../../api/resource";
import { useLoaderData } from "react-router";
import React, { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {useNavigate} from "react-router-dom";
export const loadOrgnizations = async () => {
  let organizations = await Resource("organizations").getAll();
  return { organizations };
};
export const Organizationsview = () => {
  const navigate = useNavigate();
  const { organizations } = useLoaderData();
  const [delShow, setDelShow] = useState(false);

  const deleteModalHandler = async () => {
    try {
      let response = await Resource("organizations").delete(delShow);

      setDelShow(false);
      
      console.log(response);
      navigate(0)
    } catch (e) {
      if (e) {
        console.log(e.response.status);
      }
    }
  };
  const handleShow = (id) => {
    setDelShow(id);
  };
  
  const columns = [
    {
      name: "Id",
      selector: (row) => row._id
    },
    {
      name: "name",
      selector: (row) => row.name
    },
    {
      name: "email",
      selector: (row) => row.email
    },
    {
      name: "active",
      selector: (row) => (row.active ? "Yes" : "No")
    },
    {
      name: "action",
      selector: (row) => (
        <>
          <div className="d-flex actionIcon">
          
            <button
              type="submit"
              className="btnEmply brdRed"
              id={row._id}
              onClick={() => handleShow(row._id)}
            >
              
              <i className="bx bxs-trash"></i>
            </button>
            
          </div>
        </>
      ),
    }
  ];
  
  return (
    <>
    
    <Modal
        size="sm"
        // show={show}
        // onHide={() => setShow(false)}
        show={delShow !== false}
        onHide={() => {
          setDelShow(false);
        }}
        aria-labelledby="example-modal-sizes-title-sm"
        className="modal-suc"
      >
        <Modal.Body className="bg-none">
          <div className="cards">
            <article className="information card-pop-branch">
              <div className="branch-modal-head">
                <></>
                <i
                  className="bx bx-message-square-x bx-icon-branch-close"
                  onClick={() => setDelShow(false)}
                ></i>
              </div>
              <div>
                <h3 className="modal-title-branch">
                  Are you sure you want to delete this Organization ?
                </h3>
              </div>
              <div className="modal-btns">
                <button
                  className="btn-cancel"
                  onClick={() => setDelShow(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn-delete"
                  id=""
                  onClick={deleteModalHandler}
                >
                  Delete
                </button>
              </div>
              <dl className="details">
                <div></div>
              </dl>
            </article>
          </div>
        </Modal.Body>
      </Modal>
      <main className="">
        <section className="row main-view">
          <div className="col-lg-3"></div>
          <div className="card col-lg-8 bx-shadow p-0 bg-white">
            <div className="card-header">
              <p className="card-title gradientTitle">Organization View</p>
            </div>

            <FixedHeaderStory
              fixedHeader
              fixedHeaderScrollHeight="500px"
              columns={columns}
              data={organizations}
              pagination
              paginationPerPage={7}
            />
          </div>
          <div className="col-lg-1"></div>
        </section>
      </main>
    </>
  );
};

export default Organizationsview;
