import DataTable from "react-data-table-component";
import React, { useMemo, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";

import { METHODS, commonRequest, commonRequestV1 } from "../../api/common";

import scan from "../../assets/scan.gif";
import scanning from "../../assets/biometric1.gif";
import DashImg from "../../assets/dashboardimg.png";
import Modal from "react-bootstrap/Modal";
import { matchSorter } from "match-sorter";
import { matchFingerPrint, scanFingerPrint } from "../../api/fingerprint";
import Resource from "../../api/resource";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { Loading } from "../util/loading";
import successImg from "../../assets/success.gif";
import failed from "../../assets/wrong.gif";
import { errorCodes } from "../employees/employee";
export const formatTime = (ms) => {
  const seconds = Math.floor((ms / 1000) % 60);
  const minutes = Math.floor((ms / (1000 * 60)) % 60);
  const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
  const days = Math.floor(ms / (1000 * 60 * 60 * 24));

  const timeArray = [];

  if (hours > 0) {
    timeArray.push(`${hours}h`);
  }
  if (minutes > 0) {
    timeArray.push(`${minutes}m`);
  }

  if (timeArray.length === 0) {
    return "0h 0m";
  } else {
    return timeArray.join(" ");
  }
};

export const formatDate = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const AttendanceRecorder = () => {
  const navigate = useNavigate();

  const [smShow, setSmShow] = useState(false);
  const [matched, setMatched] = useState(undefined);

  const [data, setData] = useState(undefined);

  const [error, setError] = useState(undefined);

  useEffect(() => {
    let g = async () => {
      let today = new Date();

      today.setHours(0, 0, 0, 0);
      let startDate = today.toISOString();
      today.setHours(23, 59, 59, 999);
      let endDate = today.toISOString();
      let response = await commonRequest(
        METHODS.get,
        `/attendance/?startDate=${startDate}&endDate=${endDate}`
      );

      response.forEach((element) => {
        element.timings = element.timings.map(
          (time) => new Date(time.time["$date"])
        );

        let timings = element.timings;
        let logins = [];
        let logouts = [];

        timings.forEach((time, index) => {
          if (index % 2 === 0) {
            // Even means Login
            logins.push(time);
          } else {
            logouts.push(time);
          }
        });

        let totalLoginTime = 0;

        for (let i = 0; i < logins.length; i++) {
          totalLoginTime +=
            (logouts[i] || new Date()).getTime() - logins[i].getTime();
        }

        element.logins = logins;
        element.logouts = logouts;
        element.totalLoginTime = totalLoginTime;
      });

      console.log(response);
      setData(response);
    };
    g();
  }, []);

  const columns = [
    { name: "Employee Id", selector: "empNo" },
    { name: "Name", selector: "name" },

    // { name: "Number", selector: "number" },

    {
      name: "Login",
      selector: (user) => {
        return (
          <>
            {user.logins.map((login) => (
              <>
                {formatDate(login)}
                <br />
              </>
            ))}
          </>
        );
      },
    },
    {
      name: "Logout",
      selector: (user) => {
        return (
          <>
            {user.logouts.map((logout) => (
              <>
                {formatDate(logout)}
                <br />
              </>
            ))}
          </>
        );
      },
    },

    {
      name: "Login Time",
      selector: (user) => {
        return <>{formatTime(user.totalLoginTime)}</>;
      },
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <div className="d-flex">
            <div className="button-scan" onClick={() => setSmShow(row._id)}>
              Scan
              <div></div>
              <i className="bx bx-fingerprint"></i>
            </div>
          </div>
        </>
      ),
    },
  ];

  const [filterText, setFilterText] = React.useState("");

  const filterItems = matchSorter(data || [], filterText, {
    keys: ["id", "name", "age", "email","empNo"],
  });

  useEffect(() => {
    setMatched(undefined);
    setError(undefined);
  }, [smShow]);

  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  return (
    <div className="col-lg-12 mbPad0">
      <section className="reactDatatable position-relative">
        {data ? (
          <DataTable
            title="Employee Details"
            columns={columns}
            data={filterItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            persistTableHead
          />
        ) : (
          <Loading />
        )}
        <div className="search-box">
          <input
            className="search-input"
            type="search"
            placeholder="Search something.."
            onChange={(e) => setFilterText(e.target.value)}
          />
          <button className="search-btn">
            <i className="bx bx-search"></i>
          </button>
        </div>
      </section>

      <Modal
        size="md"
        centered
        show={smShow ? true : false}
        onHide={() => setSmShow(false)}
        aria_labelledby="contained-modal-title-vcenter"
        onShow={async () => {
          try {
            let response = await commonRequest(
              METHODS.get,
              `/users/${smShow}/templates`
            );

            console.log(response);

            let templates =
              response[0] && response[0].fingerprints
                ? response[0].fingerprints
                : undefined;

            if (!templates || templates == []) {
              return setError("Fingerprint is not supported");
            }

            // Ignore the Match Score and do the Rest --->

            try {
              let fingerPrint = await scanFingerPrint();

              let finger_response = fingerPrint.data;

              let error_code = finger_response.ErrorCode;

              if (error_code !== 0) {
                let errorStatement = errorCodes[error_code] || "Unknown Error";
                setError(errorStatement);
                return;
              }

              // Successful response
              let new_template = finger_response.TemplateBase64;

              let fingerprintMatched = false;
              let matchingError = false;

              for (const template of templates) {
                try {
                  let response = (
                    await matchFingerPrint(template, new_template)
                  ).data;

                  console.log(response);

                  if (response.ErrorCode !== 0) {
                    setError(errorCodes[response.ErrorCode] || "Unknown Error");
                    matchingError = true;
                    break;
                  }

                  // { "ErrorCode": 0 , "MatchingScore": 195 }
                  let matching_score = response.MatchingScore;

                  if (matching_score > (process.env.MatchingScore || 50)) {
                    // Matching Score is suffient
                    fingerprintMatched = true;
                    break;
                  }
                } catch (e) {
                  console.error(e);
                  setError("Something Went Wrong ..! Please try again ");
                }
              }

              if (matchingError) {
                return;
              }

              setMatched(fingerprintMatched);

              if (fingerprintMatched) {
                try {
                  let r = await commonRequestV1(
                    METHODS.post,
                    `/attendance/${smShow}`
                  );

                  data.forEach((element) => {
                    if (element._id === smShow) {
                      // Found Match
                      if (
                        element.logins.length === 0 ||
                        element.logins.length === element.logouts.length
                      ) {
                        element.logins.push(new Date());
                      } else {
                        element.logouts.push(new Date());
                      }
                    }
                  });

                  setData([...data]);

                  console.log(r);
                } catch (e) {
                  if (e.response.status === 401) {
                    return navigate("/login");
                  }
                }

                return setTimeout(() => setSmShow(undefined), 3000);
              }
            } catch (e) {
              console.error(e);
              setError(
                "Something Went Wrong ...! Make Sure Secugen Web API installed"
              );
            }
          } catch (e) {
            console.error(e);
            if (e.response.status === 401) {
              return navigate("/login");
            }
          }
        }}
        // aria-labelledby="example-modal-sizes-title-sm"
        className="modal-suc"
      >
        <Modal.Body className="bg-none">
          {matched === undefined ? (
            <div className="cards cardsPop">
              <article className="information card-pop">
                <h2 className="title">
                  {error
                    ? `Error:-  ${error}`
                    : "Please Place Your finger on Device. Thank you"}
                </h2>
                <p className="info">{}</p>
                <dl className="details">
                  <div>
                    <img src={scanning} alt="" srcset="" className="w-100" />
                  </div>
                </dl>
              </article>
            </div>
          ) : matched ? (
            <>
              {" "}
              <main className="mainHomeModal">
                <div className="d-flex justify-content-center">
                  <img src={successImg} alt="" srcset="" className="w-30p" />
                </div>
                <p className="text-center TextMsgOnscan mb-4">
                  Matched successfully.
                </p>
                <div className="d-flex justify-content-center">
                  <button
                    className="cssbuttons-io-button"
                    onClick={() => setSmShow(false)}
                  >
                    Close
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path
                          fill="white"
                          d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
              </main>
            </>
          ) : (
            <>
              {" "}
              <main className="mainHomeModal">
                <div className="d-flex justify-content-center">
                  <img src={failed} alt="" srcset="" className="w-30p" />
                </div>
                <p className="text-center TextMsgOnscan mb-4">
                  Not Matched Try Again.
                </p>
                <div className="d-flex justify-content-center">
                  <button
                    className="cssbuttons-io-button"
                    onClick={() => setSmShow(false)}
                  >
                    Close
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path
                          fill="white"
                          d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
              </main>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export const Home = () => {
  const { analytics, user } = useRouteLoaderData("main");
  const navigate = useNavigate();

  const [mode, setMode] = useState("light");

  return (
    <>
      <body className={mode === "light" ? "light-mode" : "dark-mode"}>
        <div id="global-loader" style={{ display: "none" }}>
          <img
            src="../assets/img/loader.svg"
            className="loader-img"
            alt="Loader"
          />
        </div>
        {/* main page */}
        <div className="page">
          <div>{/*Main Header */}</div>
          {/* Main Content*/}

          <div className="side-app">
            <div className="main-container container-fluid row">
              {/* Page Header */}

              <div className="col-sm-12 col-lg-6">
                <div className="page-header">
                  <div>
                    <h2 className="main-content-title tx-24 mg-b-5 titleDash">
                      Welcome to{" "}
                      <span className="highlight_dashboard">
                        {analytics.name}{" "}
                        {analytics.branch
                          ? `- ${analytics.branch[0].name}`
                          : ""}
                      </span>
                    </h2>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>

                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Dashboard
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="card dashboardcard">
                  <div className="dashboardcardbody position-relative">
                    <div className="row align-items-center">
                      <div className="col-lg-5"></div>
                      <div className="col-lg-7 img-bg d-flex">
                        <div className="plr-20">
                          <h4 className="d-flex mb-3">
                            <span className="font-weight-bold text-white ">
                              {analytics.name} !
                            </span>
                          </h4>
                          <p className="dashboardquote">
                            &ldquo; Develop success from failures.
                            Discouragement and failure are two of the surest
                            stepping stones to success. &rdquo;
                          </p>
                        </div>
                        <div></div>
                      </div>
                      <img src={DashImg} alt="user-img" />
                    </div>
                    {/* <div>
                     <img src={morning} alt="morning" className="dayImg"/>
                     </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {/* profile card */}
                <div className="col-12">
                  <div className="cardDashboardProfile">
                    <div className="card-body">
                      <div className="row align-items-end bg-white b-radius">
                        <div className="col-sm-8 mbDflex mbalignItems desktopflex">
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-soft-warning rounded fs-3">
                              {/* <i className="bx bx-user-circle" /> */}
                              <i className="bx bx-user"></i>
                            </span>
                          </div>
                          <div className="profileSectionDashboard">
                            <p className="lh-base">
                              <i className="bx bx-user iconDash"></i>{" "}
                              <span className="profileNameD">{user.name}</span>
                            </p>
                            <p className="ProfileDesig lh-base">
                              <i className="bx bxs-user-detail iconDash"></i>{" "}
                              {user.role == "HR"
                                ? "HR Manager"
                                : "Branch Manager"}
                            </p>

                            <div className="mt-3 mbNone">
                              <button
                                className="cardViewProfile"
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate("/home/profile");
                                }}
                              >
                                View Profile{" "}
                                <i className="bx bx-chevrons-right"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="">
                            <img
                              src="https://themesbrand.com/velzon/html/material/assets/images/user-illustarator-2.png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end card-body*/}
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-lg-6 asideCards1"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate("/home/employees");
                    }}
                  >
                    <div className="card card-animate brd-none">
                      <div className="card-body ">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-muted text-truncate mb-0 text-cardMain">
                              {" "}
                              Total Employees
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                              <span className="counterDvalue">
                                {analytics.users[0].count}
                              </span>
                            </h4>
                            <a href className="">
                              {" "}
                              <span className="badge text-success mb-0">
                                <i className="bx bx-up-arrow-alt  align-middle smallArrow"></i>{" "}
                                16.24 %
                              </span>
                              <button type="button" className="btn-badge">
                                <i className="bx bx-lock-open-alt"></i> view
                              </button>
                            </a>
                          </div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-soft-success rounded fs-3 bg-iconSub">
                              <i className="bx bx-group iconmainSub"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* end card body */}
                    </div>
                  </div>
                  {user.role == "HR" && (
                    <div
                      className="col-lg-6 asideCards2 mbPad0"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/home/branches");
                      }}
                    >
                      <div className="card card-animate brd-none mbPad0">
                        <div className="card-body ">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="text-muted text-truncate mb-0 text-cardMain">
                                {" "}
                                Total Branches
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-end justify-content-between mt-4">
                            <div>
                              <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                <span className="counterDvalue">
                                  {analytics.branches
                                    ? analytics.branches[0]?.count || 0
                                    : 0}
                                </span>
                              </h4>
                              <a href className="">
                                {" "}
                                <span className="badge text-success mb-0">
                                  <i className="bx bx-up-arrow-alt  align-middle smallArrow"></i>{" "}
                                  16.24 %
                                </span>
                                <button type="button" className="btn-badge">
                                  <i className="bx bx-lock-open-alt"></i> view
                                </button>
                              </a>
                            </div>
                            <div className="avatar-sm flex-shrink-0">
                              <span className="avatar-title bg-soft-success rounded fs-3 bg-iconSub">
                                <i className="bx bx-building-house iconmainSub"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* end card body */}
                      </div>
                    </div>
                  )}
                </div>
                {/* ended Profile card */}
              </div>
              <AttendanceRecorder />
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default Home;
