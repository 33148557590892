import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactDOM from "react-dom/client";
import Login from "./components/login/login";
import Admin from "./admin/dashboard/index";
import { analyticsLoader, DashBoard } from "./admin/dashboard/dashboard";
import Home from "./components/home/home";
import Admindashboard, {
  MainLoader,
  RootLoader,
} from "./components/home/index";
import Organization from "./admin/organization/organization";
import Organizationview, {
  loadOrgnizations,
} from "./admin/organization/organizationview";
import Hrcreation from "./admin/hr/createhr";
import Branch from "./components/branch/branch";
import Employees from "./components/employees/employee";
import Employeedetails, {
  EmployessLoader,
} from "./components/employees/employeedetail";
import Branchdetails from "./components/branch/branchdetails";
import "./styles/global.css";
import "./styles/customstyle.css";
import { LoginIndex } from "./components/login";
import Reset from "./components/resetPassword/reset";
import ResetPassword, {
  ResetPasswordLoader,
} from "./components/resetPassword/resetPassword";
import { Employeecomponent } from "./components/employees";
import { Branchdashboard } from "./components/branch/index";
import { Organizationhome } from "./admin/organization/index";
import {
  branchesLoader,
  branchLoader,
  employeeEditLoader,
  leavesLoader,
} from "./loaders/branches";
import { Tracing } from "./components/tracing/tracing";
import { HrOnly } from "./components/security/Check";
import { Profile } from "./components/profile/index";
import Leave from "./components/Leave/LeaveHistory";
import ApplyLeave from "./components/Leave/applyLeave";
import Leavedashboard from "./components/Leave/index";
import ViewLeave, { LeaveLoader } from "./components/Leave/viewLeave";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import { Hrhome } from "./admin/hr/index";
import Hrdetails, {HRsLoader} from "./components/hrs/hrdetail";


const router = createBrowserRouter([
  {
    path: "/",
    loader: RootLoader,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
  {
    path: "/login",
    element: <LoginIndex />,
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: "reset",
        element: <Reset />,
      },
      {
        path: "reset/password",
        element: <ResetPassword />,
        loader: ResetPasswordLoader,
      },
    ],
  },
 
  {
    path: "/home",
    element: <Admindashboard />,
    id: "main",
    loader: MainLoader,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "track",
        element: <Tracing />,
        loader: branchesLoader,
      },
      {
        path: "leaves",
        element: <Leavedashboard />,
        children: [
          {
            index: true,
            element: <Leave />,
          },
          {
            path: "new",
            element: <ApplyLeave />,
            loader: leavesLoader,
          },
          {
            path: ":requestId",
            element: <ViewLeave />,
            loader: LeaveLoader,
          },
        ],
      },

      {
        path: "profile",
        element: <Profile />,
        loader: MainLoader,
      },
     
      {
        path: "employees",
        element: <Employeecomponent />,
        children: [
          {
            index: true,
            element: <Employeedetails />,
            loader: EmployessLoader,
          },
          {
            path: "new",
            element: <Employees />,
            loader: branchesLoader,
          },
          {
            path: ":userId/edit",
            element: (
              <HrOnly>
                <Employees />
              </HrOnly>
            ),
            loader: employeeEditLoader,
          },
        ],
      },

      {
        path: "branches",
        element: (
          <HrOnly>
            <Branchdashboard />
          </HrOnly>
        ),
        children: [
          {
            index: true,
            element: <Branch />,
            loader: branchesLoader,
          },
          {
            path: "new",
            element: <Branchdetails />,
          },
          {
            path: ":branchId/edit",
            element: <Branchdetails />,
            loader: branchLoader,
          },
        ],
      },
    ],
  },
  {
    path: "admin",
    element: <Admin />,
    children: [
      {
        index: true,
        element: <DashBoard />,
        loader: analyticsLoader,
      },
      {
        path: "organization",
        element: <Organizationhome />,
        children: [
          {
            index: true,
            element: <Organizationview />,
            loader: loadOrgnizations,
          },
          {
            path: "new",
            element: <Organization />,
          },
        ],
      },

      {
        path: "hr",
        element: <Hrhome />,

        children: [
          {
            path: "new",
            element: <Hrcreation />,
            loader: loadOrgnizations,
          },
          {
            index: true,
            element: <Hrdetails/>,
            loader: HRsLoader
          }
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
