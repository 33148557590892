import React, { useEffect, useMemo, useState } from "react";
import custom from "../../styles/check.module.css";
import check from "../../styles/check.module.css";
import {
  Link,
  useLoaderData,
  useRouteLoaderData,
  useNavigate
} from "react-router-dom";
import Resource from "../../api/resource";
import { scanFingerPrint } from "../../api/fingerprint";
import { ButtonLoading, Loading } from "../util/loading";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import scanning from "../../assets/bimetricplain.gif";
import fingerImg from "../../assets/fingerprintbro.svg";
import successImg from "../../assets/success.gif";
import axios from "axios";
import { BASE_URL, commonRequest } from "../../api/common";

const sentences = [
  "Keep Lifting your finger to add the different parts of your fingerprint",
  "To add the different parts of your fingerprint, continue lifting your finger.",
  "Lift your finger repeatedly to include all the unique sections of your fingerprint.",
  "Add each distinct part of your fingerprint by lifting your finger repeatedly.",
  "By repeatedly lifting your finger, you can add all the various sections of your fingerprint.",
  "Keep raising your finger to capture all the different components of your fingerprint.",
  "Lift your finger multiple times to record all the distinct elements of your fingerprint.",
  "To ensure all parts of your fingerprint are captured, lift your finger repeatedly while scanning.",
  "Keep raising and lowering your finger to capture all the unique characteristics of your fingerprint.",
  "Lift your finger each time to add the different segments of your fingerprint to the scanner.",
  "Repeat lifting your finger to add all the different features of your fingerprint to the system."
];

export const errorCodes = {
  0: "No error",
  1: "Creation failed (fingerprint reader not correctly installed or driver files error)",
  2: "Function failed (wrong type of fingerprint reader or not correctly installed)",
  3: "Internal (invalid parameters to sensor API)",
  5: "DLL load failed",
  6: "DLL load failed for driver",
  7: "DLL load failed for algorithm",
  51: "System file load failure",
  52: "Sensor chip initialization failed",
  53: "Sensor line dropped",
  54: "Timeout",
  55: "Device not found",
  56: "Driver load failed",
  57: "Wrong image",
  58: "Lack of bandwidth",
  59: "Device busy",
  60: "Cannot get serial number of the device",
  61: "Unsupported device",
  101: "Very low minutiae count",
  102: "Wrong template type",
  103: "Invalid template",
  104: "Invalid template",
  105: "Could not extract features",
  106: "Match failed",
  1000: "No memory",
  4000: "Invalid parameter passed to service",
  2000: "Internal error",
  3000: "Internal error extended",
  6000: "Certificate error cannot decode",
  10001: "License error",
  10002: "Invalid domain",
  10003: "License expired",
  10004: "WebAPI may not have received the origin header from the browser"
};

const getMessage = () => {
  const randomIndex = Math.floor(Math.random() * sentences.length);
  return sentences[randomIndex];
};

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const FINGERPRINT_LIMIT = process.env.FINGERPRINT_LIMIT || 10;

const FingerPrintTaker = ({ data, setData }) => {
  const [modalShow, setModalShow] = useState(false);
  const [showscan, setShowscan] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(undefined);

  // const [fingerprints, setFingerprints] = useState(data);
  let fingerprints = data;
  let setFingerprints = (fingerprint) =>
    setData([...fingerprints, fingerprint]);

  const sentense = useMemo(() => getMessage(), [fingerprints.length]);

  useEffect(() => {
    const g = async () => {
      let i = fingerprints.length;

      if (i < FINGERPRINT_LIMIT) {
        try {
          let r = await scanFingerPrint();

          console.log(r.data);

          let data = r.data;

          let errorCode = data.ErrorCode; //{ErrorCode: 54}

          if (errorCode != 0) {
            // Error Code = 0 is Successfully Message
            let errorCodeStatement = errorCodes[errorCode] || "Unkown Error";
            setError(errorCodeStatement);
            await sleep(3000);
            return;
          }
          setError(undefined);
          await sleep(3000);
          
          r.data.BMPBase64 = undefined
          r.data.ISOTemplateBase64 = undefined
           
          setFingerprints(r.data); //((fingerprints) => [...fingerprints, r.data]);
        } catch (e) {
          console.log(e);
          setError(
            "Something Went Wrong ...! Make Sure Secugen Web API installed"
          );
          return;
        }
      } else {
        setShowscan(false);
        setSuccess(true);
      }
    };
    if (modalShow) {
      g();
    }
  }, [fingerprints, modalShow]);

 

  return (
    <div className="col-lg-6 col-md-6 equal-form-space">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
        onShow={() => setShowscan(true)}

        // onShow={async () => {
        //   setShowscan(true); // Showing the Fingerprint Image on Modal

        //   let i = fingerprints.length;

        //   while (i < FINGERPRINT_LIMIT) {
        //     try {
        //       let r = await scanFingerPrint();

        //       console.log(r.data);

        //       let data = r.data;

        //       let errorCode = data.ErrorCode; //{ErrorCode: 54}

        //       if (errorCode != 0) {
        //         // Error Code = 0 is Successfully Message
        //         let errorCodeStatement =
        //           errorCodes[errorCode] || "Unkown Error";
        //         setError(errorCodeStatement);
        //         await sleep(3000);
        //         continue;
        //       }
        //       setError(undefined);
        //       setFingerprints(r.data); //((fingerprints) => [...fingerprints, r.data]);
        //       await sleep(3000);
        //       i++;
        //     } catch (e) {
        //       console.log(e);
        //       setError(
        //         "Something Went Wrong ...! Make Sure Secugen Web API installed"
        //       );
        //       return;
        //     }
        //   }
        //   // setData(fingerprints);
        //   setShowscan(false);
        //   setSuccess(true);
        // }}
      >
        <Modal.Body>
          {showscan ? (
            <>
              <main className="mainScanTrigger">
                <section className="position-relative">
                  <svg className="progress-ring" width={120} height={120}>
                    <circle
                      className="progress-ring__circle"
                      stroke={error ? "red" : "green"}
                      strokeWidth={4}
                      fill="transparent"
                      r={52}
                      cx={60}
                      cy={60}
                      style={{
                        strokeDasharray: `${fingerprints.length * 35},330`
                      }}
                    />
                  </svg>
                  <img src={scanning} alt="" srcset="" className="scanImg" />
                </section>
              </main>
              <p className="TextMsgOnscan">
                {error ? `Error:- ${error}. Please Try Again` : sentense}
              </p>
            </>
          ) : success ? (
            <main>
              <div className="d-flex justify-content-center">
                <img src={successImg} alt="" srcset="" className="w-30p" />
              </div>
              <p className="text-center TextMsgOnscan mb-4">
                Scanning process completed successfully.
              </p>
              <div className="d-flex justify-content-around">
              
              <button
                  className="cssbuttons-io-button"
                  onClick={() => {setData([]);setModalShow(false)}}
                >
                  Clear 
                  <div className="icon">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path
                        fill="white"
                        d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                      ></path>
                    </svg> */}
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #ffffff;transform: ;msFilter:;"><path d="M16 2H8C4.691 2 2 4.691 2 8v13a1 1 0 0 0 1 1h13c3.309 0 6-2.691 6-6V8c0-3.309-2.691-6-6-6zm4 14c0 2.206-1.794 4-4 4H4V8c0-2.206 1.794-4 4-4h8c2.206 0 4 1.794 4 4v8z"></path><path d="M15.292 7.295 12 10.587 8.708 7.295 7.294 8.709l3.292 3.292-3.292 3.292 1.414 1.414L12 13.415l3.292 3.292 1.414-1.414-3.292-3.292 3.292-3.292z"></path></svg> */}
                    <i class='bx bx-message-square-x'></i>
                  </div>
                  
                </button>

                <button
                  className="cssbuttons-io-button"
                  onClick={() => setModalShow(false)}
                >
                  Close
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path
                        fill="white"
                        d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                      ></path>
                    </svg>
                  </div>
                </button>
              </div>
            </main>
          ) : undefined}
        </Modal.Body>
      </Modal>

      <aside className="d-flex justify-content-between choose-color">
        {/* <div>
          <img src={fingerImg} alt="" srcset="" className="fingerShowImg" />
        </div> */}
        <div>
          <p
            className="cssbuttons-io-button"
            onClick={() => setModalShow(true)}
          >
            Scan
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path
                  fill="white"
                  d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                ></path>
              </svg>
            </div>
          </p>
        </div>
      </aside>
    </div>
  );
};

const Employees = () => {
  const { branches, employee } = useLoaderData();
  const [loading, setLoading] = useState(false);

  const { user } = useRouteLoaderData("main");

  const [emp, setEmp] = useState(
    employee || {
      organization: "something",
      role: user.role === "HR" ? "Manager" : "Employee",
      active: true,
      branch: user.role === "Manager" ? branches[0]._id : undefined,
      photo: "",
      photoId: ""
    }
  );
  const ImageComponent = ({ type }) => {
    const base64String = type;

    const imageSrc = base64String;

    return <img src={imageSrc} alt="Image" className="ImgBackend" />;
  };

  const [checked, setChecked] = useState(false);
  const [show, setShow] = useState(false);

  const update = (e) => setEmp({ ...emp, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let resource = Resource("users");
      let r = emp._id
        ? await resource.update(emp, checked)
        : await resource.create(emp, checked);
      // let r = await Resource("users").create(emp, checked);
      console.log(r);
      console.log("====>", emp);
    } catch (e) {
      console.log(e);
      console.log("====>", emp);
    }

    setLoading(false);
    setShow(true);
    setTimeout(() => {
      setShow(false);
      navigate("/home/employees");
    }, 2000);
  };
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    navigate("/home/employees");
  };

  const uploadToS3 = async form => {
    try {
      let formData = new FormData();
      formData.append("file", form[0]);
      let response = await commonRequest("post", "/files/", formData);
      
      return response?.url
      
    } catch (e) {
      alert("Unable to Upload the Image to Serve")
    }
  }
  return (
    <div>
      <main className="mainCardContainer">
        <div className="row">
          <div className="col-lg-12 bg-white bx-shadow border-radius5">
            <header className="d-flex justify-content-between position-relative ptb-20">
              <div className="card-header">
                <p className="card-title employeeTitle">
                  {emp._id ? "Update" : "Create"} Employee
                </p>
              </div>
            </header>
            <form onSubmit={onSubmit}>
              <section className="row">
                <div className="col-lg-4 equal-form-space">
                  <div>
                    <label className="form-label" htmlFor="exampleInputname">
                      Name
                    </label>
                    <input
                      placeholder="Name"
                      type="text"
                      name="name"
                      value={emp.name}
                      onChange={update}
                      id="exampleInputname"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-4 equal-form-space">
                  <div>
                    <label className="form-label" htmlFor="exampleInputname">
                      Father Name
                    </label>
                    <input
                      placeholder="Name"
                      type="text"
                      name="fatherName"
                      value={emp.fatherName}
                      onChange={update}
                      id="exampleInputname"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-4 equal-form-space">
                  <div>
                    <label className="form-label" htmlFor="exampleInputname">
                      Qualification
                    </label>
                    <input
                      placeholder="Name"
                      type="text"
                      name="qualification"
                      value={emp.qualification}
                      onChange={update}
                      id="exampleInputname"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-4 equal-form-space">
                  <div>
                    <label className="form-label" htmlFor="exampleInputname">
                      Date Of Birth
                    </label>
                    <input
                      placeholder="Name"
                      type="date"
                      name="dob"
                      value={emp.dob}
                      onChange={update}
                      id="exampleInputname"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-4 equal-form-space">
                  <div>
                    <label className="form-label" htmlFor="exampleInputname">
                      Gender
                    </label>
                    <select
                      name="gender"
                      value={emp.gender}
                      onChange={update}
                      id="exampleInputname"
                      className="form-control"
                      required
                    >
                      <option value={0}>Select gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                    </select>
                    {/* <input
                    placeholder="Name"
                    type="text"
                    name="name"
                    value={emp.name}
                    onChange={update}
                    id="exampleInputname"
                    className="form-control"
                  /> */}
                  </div>
                </div>

                <div className="col-lg-4 equal-form-space">
                  <div>
                    <label className="form-label" htmlFor="exampleInputname1">
                      Aadhar Number
                    </label>
                    <input
                      placeholder="Aadhar Number"
                      type="text"
                      onChange={update}
                      value={emp.aadhar}
                      maxLength={16}
                      name="aadhar"
                      id="exampleInputname1"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-4 equal-form-space">
                  <div>
                    <label className="form-label" htmlFor="exampleInputname1">
                      Pan Number
                    </label>
                    <input
                      placeholder="Pan number"
                      type="text"
                      onChange={update}
                      value={emp.pan}
                      maxLength={10}
                      name="pan"
                      id="exampleInputname1"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-4 equal-form-space">
                  <div>
                    <label className="form-label" htmlFor="exampleInputname1">
                      Upload Photo
                    </label>
                    <input
                      placeholder="Employee photo"
                      type="file"
                      name="photo"
                      // value={emp.photo}
                      id="exampleInputname1"
                      className="form-control"
                      onChange={async (e) => {
                        let url = await uploadToS3(e.target.files);
                        setEmp({ ...emp, photo: url })
                      }}
                      required={emp.photo === ""}
                    />
                    {emp._id ? (
                      <>
                        <ImageComponent type={emp.photo} />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 equal-form-space">
                  <div>
                    <label className="form-label" htmlFor="exampleInputname1">
                      ID Proof Upload
                    </label>
                    <input
                      placeholder="Id Proof Upload"
                      type="file"
                      name="photoId"
                      // value={emp.photoId}
                      id="exampleInputname1"
                      className="form-control"
                      onChange={async (e) => {
                        let url = await uploadToS3(e.target.files);
                        setEmp({ ...emp, photoId: url });
                      }}
                      required={emp.photoId === ""}
                    />
                    {emp._id ? (
                      <>
                        <ImageComponent type={emp.photoId} />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 equal-form-space">
                  <div>
                    <label className="form-label" htmlFor="exampleInputname1">
                      Employee Id
                    </label>
                    <input
                      placeholder="employee Id"
                      type="text"
                      onChange={update}
                      value={emp.empNo}
                      name="empNo"
                      id="exampleInputname1"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-4 equal-form-space">
                  <div>
                    <label className="form-label" htmlFor="exampleInputname1">
                      Email
                    </label>
                    <input
                      placeholder="E-mail"
                      type="email"
                      onChange={update}
                      value={emp.email}
                      name="email"
                      id="exampleInputname1"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-4 equal-form-space">
                  <div>
                    <label className="form-label" htmlFor="exampleInputname">
                      Number
                    </label>
                    <input
                      placeholder="Number"
                      type="number"
                      name="mobile"
                      value={emp.mobile}
                      onChange={update}
                      maxLength={10}
                      id="exampleInputname"
                      className="form-control"
                      required
                    />
                  </div>
                </div>

                <div className="col-lg-4 equal-form-space">
                  <div>
                    <label className="form-label" htmlFor="exampleInputname">
                      Date of joining
                    </label>
                    <input
                      placeholder="Logo URL"
                      type="date"
                      name="joiningDate"
                      value={emp.joiningDate}
                      onChange={update}
                      id="exampleInputname"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                {user.role === "HR" && (
                  <div className="col-lg-4 equal-form-space">
                    <div>
                      <label className="form-label" htmlFor="exampleInputname">
                        Select Branch
                      </label>

                      <select
                        name="branch"
                        id=""
                        className="form-control"
                        onChange={update}
                        required
                      >
                        <option value={0}>Select Branch</option>
                        {branches &&
                          branches.map((branch) => (
                            <option
                              value={branch._id}
                              selected={branch._id === emp.branch}
                            >
                              {branch.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                )}
                {user.role === "HR" && (
                  <div className="col-lg-4 equal-form-space">
                    <div>
                      <label className="form-label" htmlFor="exampleInputname">
                        Select Role
                      </label>

                      <select
                        name="role"
                        id=""
                        className="form-control"
                        onChange={update}
                        required
                      >
                        <option
                          value="Manager"
                          selected={emp.role === "Manager"}
                        >
                          Manager
                        </option>

                        <option
                          value="Employee"
                          selected={emp.role === "Employee"}
                        >
                          Employee
                        </option>
                      </select>
                    </div>
                  </div>
                )}

                <div className="col-lg-4 equal-form-space">
                  <div>
                    <label className="form-label" htmlFor="exampleInputname">
                      Designation
                    </label>
                    <input
                      placeholder="Designation"
                      type="text"
                      name="designation"
                      onChange={update}
                      value={emp.designation}
                      id="exampleInputname"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-4 equal-form-space">
                  <div>
                    <label className="form-label" htmlFor="exampleInputname">
                      Department
                    </label>
                    <input
                      placeholder="Human Resource"
                      type="text"
                      id="exampleInputname"
                      name="department"
                      onChange={update}
                      value={emp.department}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-4 equal-form-space">
                  <div>
                    <label className="form-label" htmlFor="exampleInputname">
                      Address
                    </label>
                    <input
                      placeholder="Address"
                      type="text"
                      id="exampleInputname"
                      name="address"
                      onChange={update}
                      value={emp.address}
                      className="form-control"
                    />
                  </div>
                </div>
                {emp.role === "Manager" ? (
                  <div className="col-lg-4 equal-form-space">
                    <aside className="d-flex justify-content-between choose-color">
                      <div>
                        <p>Active</p>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          className={check.tog}
                          defaultChecked={emp.active}
                          onChange={() =>
                            setEmp({ ...emp, active: !emp.active })
                          }
                        />
                      </div>
                    </aside>
                  </div>
                ) : (
                  <></>
                )}
                {/* {emp.role === "Manager" ? (
                <div className="col-lg-6 col-md-6 equal-form-space">
                  <aside className="d-flex justify-content-between choose-color">
                    <div>
                      <p>Invite</p>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        className={check.tog}
                        defaultChecked={checked}
                        onChange={() => setChecked(!checked)}
                      />
                    </div>
                  </aside>
                </div>
              ) : (
                <></>
              )} */}

                <FingerPrintTaker
                  data={emp.fingerprint || []}
                  setData={(data) =>
                    setEmp({
                      ...emp,
                      fingerprint: data
                    })
                  }
                />

                <div className="col-lg-12 d-flex justify-content-center mtb-20">
                  <div>
                    <button
                      className="cssbuttons-io-button"
                      style={loading ? { background: "black" } : {}}
                    >
                      {loading ? (
                        <ButtonLoading />
                      ) : (
                        <>
                          {" "}
                          {emp._id ? "Update" : "Submit"}
                          <div className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path
                                fill="white"
                                d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                              ></path>
                            </svg>
                          </div>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </section>
            </form>
          </div>
        </div>
      </main>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className="modal-suc"
      >
        <Modal.Body className="bg-none">
          <div className="cards">
            <article className="information card-pop-branch">
              <div className="branch-modal-head">
                <></>
                <i
                  className="bx bx-message-square-x bx-icon-branch-close"
                  onClick={handleClose}
                ></i>
              </div>
              <div>
                <h3 className="modal-title-branch">
                  Employee has been {emp._id ? "Updated" : "Created"}{" "}
                  successfully.
                </h3>
              </div>
              <div className="modal-btns">
                <button className="btn-delete" onClick={() => setShow(false)}>
                  Close
                </button>
                {/* <button
                    className="btn-delete"
                    id=""
                    // onClick={deleteModalHandler}
                  >
                    Delete
                  </button> */}
              </div>
              <dl className="details">
                <div></div>
              </dl>
            </article>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export const loaderForHR = async () => {};

export default Employees;
