import profileimg from "../../assets/profileavatar.png";
import india from "../../assets/india.png";
import Role from "../../assets/role.png";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
export const Profile= () =>{
    const { user } = useRouteLoaderData("main");
    console.log("Hello user data",user);
    return (
        <>
        <section className="profileMain">
            <div className="profileSub">

            </div>
            <div className="ProfileImage">
                <img src={profileimg} alt="" srcset="" />
               <div className="ProfileTitle">
               <h3>{user.name}</h3>
               </div>
                <div className="CountryCode">
                    <img src={india} alt="" />
                    <p>India</p>
                </div>
                <div className="ProfileDetail">
                    <p>{user.email}</p>
                    <div className="DesignationProfile">
                        <img src={Role} alt="" />
                        <p>{user.designation}</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}