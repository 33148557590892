import React, { useEffect } from "react";
import Logo from "../assets/logo.png";
import { useRouteLoaderData } from "react-router-dom";
import { formatTime } from "./home/home";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";

// styles for pdf

export const Pdf = ({
  pdf,
  attData,
  month,
  branch,
  cellType,
  minimumLoginTime,
  excludes,
  columns,
    analytics, user
}) => {

  return (
    <Document ref={pdf}>
      <Page size="A3" style={styles.page} orientation="landscape">
        <View>
          <Image src={Logo} style={styles.pdfLogo} />
        </View>
        <View style={[styles.section,{flexDirection:'row', justifyContent:'space-between'}]}>
          <Text style={{padding:"10px 0px",fontSize:"15px"}}>Month / Year: {month}</Text>
          <Text style={{padding:"10px 0px", fontSize:"15px"}}>Month / Year: {month}</Text>
        </View>
        <View style={{margin:"0px 20px",flexDirection:'row', justifyContent:'space-between'}}>
          <Text style={{padding:"10px 0px", fontSize:"15px"}}>Company : {analytics.name}</Text>
          {minimumLoginTime && (
              <Text style={{padding:"10px 0px", fontSize:"15px"}}>Minimum Login: {formatTime(minimumLoginTime)}</Text>
          )}
        </View>
        {branch && <View style={{margin:"0px 20px"}}>
          <Text style={{fontSize:"15px"}}>Branch: {branch?.name}</Text>
        </View>}
        <View>
          <Text style={{margin : "0px 20px", padding:"10px 0px", fontSize:"15px", paddingBottom:'25px'}}>Type : {cellType === "ll"
              ? "Login/Logout"
              : cellType === "pa"
                  ? "Present/Absent"
                  : cellType}</Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            {columns
                ? columns.map((c) => (
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{c?.name}</Text>
                    </View>

                ))
                : undefined}

          </View>
          {attData
              ? attData.map((emp, index) => {
                let pageBreak = Math.floor((index + 1)% 25) === 0;
                return (
                    <View key={emp.id} style={[styles.tableRow,pageBreak ? {borderTop:"1px solid #000", marginTop:"10px"}:{} ]} break={pageBreak}>
                      {columns.map((col) => (
                          <View style={styles.tableCol}>
                            <Text style={styles.tableCell} >{col.selector(emp)}</Text>
                          </View>
                      ))}
                    </View>
                );
              })
              : undefined}


        </View>
        <View style={{padding:"15px 0px", margin:"0px 20px"}}>
          <Text style={{fontSize:"15px", textAlign:"right"}}> Created By : {user.name}</Text>

        </View>
        <View style={{margin:"0px 20px"}}>
          <Text style={{fontSize:"15px", textAlign:"right"}}> Created at : {new Date().toLocaleDateString("en-Us", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })}{" "}
            {new Date().toLocaleTimeString("en-US")}</Text>
        </View>
      </Page>
    </Document>
  );
};
export default Pdf;

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
  },
  pdfLogo: {
width:"300px",
    margin:"15px"
  },

  section: {
  margin:"0px 20px",
    display:"flex"
  },



  table: {
    display: "table",
    width: "auto",
    margin:"0 5px",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {

    flexDirection: "row"
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: "5 auto",
    fontSize: 5,


  }
});
