import org from "../../assets/org_structure_icon.svg";
import attendence from "../../assets/attendance.png";
import employee from "../../assets/emp.png";
import employgroup from "../../assets/employees.png";
import greentick from "../../assets/green-tick.svg";
import neworg from "../../assets/add_org.png";
import task from "../../assets/task.png";
import { Link, useLoaderData } from "react-router-dom";
import { commonRequest, METHODS } from "../../api/common";

export const DashBoard = () => {
  const { analytics } = useLoaderData();
  const { users, organizations } = analytics;

  return (
    <>
      <div
        className="container-full container-minheight pt-68 clearfix"
        style={{}}
      >
        <div className="">
          {/*Outer Shell*/}
          <nav className="main-content main-navigation mt-x pb-50">
            <div className="container pt-24">
              {/* ============================== */}
              <div className="boxed_filter_md display-block ptb-24">
                <ul className="row">
                  <div className="col-lg-3">
                    <li className="boxHome">
                      <div className="row">
                        <div className="col-sm-7">
                          <p className="mb-0">
                            Total <br /> Organization
                          </p>
                        </div>
                        <div className="col-sm-5 text-right">
                          <div className="clock-data display-property-1">
                            <span className="db-font-icon primary-orange title-1 fw-500 line-0">
                              <img src={greentick} alt="greentick" />
                            </span>
                            {/**/}
                            <p className="m-0 primary-orange title-2 fw-500 green">
                              {" "}
                              {organizations}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                  <div className="col-lg-3">
                    <li className="boxHome">
                      <div className="row">
                        <div className="col-sm-7">
                          <p className="mb-0">
                            Total <br /> HR{" "}
                          </p>
                        </div>
                        <div className="col-sm-5 text-right">
                          <div className="clock-data display-property-1">
                            <span className="db-font-icon primary-orange title-1 fw-500 line-0">
                              <img src={greentick} alt="greentick" />
                            </span>
                            {/**/}
                            <p className="m-0 primary-orange title-2 fw-500 green">
                              {" "}
                              {users.find((user) => user._id === "HR")?.count ||
                                0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                  <div className="col-lg-3">
                    <li className="boxHome">
                      <div className="row">
                        <div className="col-sm-7">
                          <p className="mb-0">
                            Attendx <br /> Employees{" "}
                          </p>
                        </div>
                        <div className="col-sm-5 text-right">
                          <div className="clock-data display-property-1">
                            <span className="db-font-icon primary-orange title-1 fw-500 line-0">
                              <img src={greentick} alt="greentick" />
                            </span>
                            {/**/}
                            <p className="m-0 primary-orange title-2 fw-500 green">
                              {" "}
                              {users.find((user) => user._id === "Employee")
                                ?.count || 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                  <div className="col-lg-3">
                    <li className="boxHome">
                      <div className="row">
                        <div className="col-sm-7">
                          <p>
                            Total <br /> Manager's
                          </p>
                        </div>
                        <div className="col-sm-5 text-right">
                          <div className="clock-data display-property-1">
                            <span className="db-font-icon primary-orange title-1 fw-500 line-0">
                              <img src={greentick} alt="greentick" />
                            </span>
                            {/**/}
                            <p className="m-0 primary-orange title-2 fw-500 green">
                              {" "}
                              {users.find((user) => user._id === "Manager")
                                ?.count || 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                </ul>
              </div>
              {/* ============================== */}
              <div
                className="row_15 mobile-inlineBlock"
                data-step={7}
                data-tooltipclassName="align-bottom-right"
                data-intro="<b>Access Modules</b> <hr style = 'padding: 0px; border:1px dashed #cccccc;'> Access respective module pages directly from here  "
                data-position="bottom"
                hidenext="false"
              >
                <div className="col-md-4 pl-0 desktopDisplay">
                  <div className="title-1 font-16 ptb-8 pl-32 plx-0">
                    Admin Access
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="col-md-4 pl-0 mobileDisplay ml-12">
                  <div className="title-1 font-16 pt-8 pl-32 plx-0 mt-12 text-left text-center_x inline-block_x">
                    My Access
                  </div>
                </div>
                <div className="col-md-2 col-sm-2 inBlock">
                  <div className="single-menu-item">
                    <Link to="/admin/organization/new">
                      <div className="module-icon-wrapper">
                        <img src={neworg} alt="organization" />
                      </div>
                      <p>Add Org</p>
                    </Link>
                  </div>
                </div>
                {/* <div className="col-md-2 col-sm-2 inBlock">
                  <div className="single-menu-item">
                    <a href="/tasksApi/GetTasks">
                      <div className="module-icon-wrapper">
                        <img src={employee} alt="employees" />
                      </div>
                      <p>SB Force</p>
                    </a>
                  </div>
                </div> */}

                <div className="col-md-2 col-sm-2 inBlock">
                  <div
                    className="single-menu-item"
                    data-step={5}
                    data-intro="<b>Vibe</b> <hr style = 'padding: 0px; border:1px dashed #cccccc;'> Access Vibe our very own social network!! "
                    data-position="right"
                  >
                    <Link to="/admin/organization">
                      <div className="module-icon-wrapper">
                        <span
                          className="db-badge"
                          id="pending_posts_vibe"
                          style={{ display: "none" }}
                        />
                        {/* ADD COUNT FOR VIBE VIA AJAX */}
                        <img src={org} alt="neworgnization" />
                      </div>
                      <p>View Org</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-2 col-sm-2 inBlock">
                  <div className="single-menu-item">
                    <Link to="/admin/hr">
                      <div className="module-icon-wrapper">
                        <img src={employgroup} alt="biometric" />
                      </div>
                      <p>Add HR</p>
                    </Link>
                  </div>
                </div>
                {/* <div className="col-md-2 col-sm-2 inBlock">
                  <div className="single-menu-item">
                    <a href="/attendance">
                      <div className="module-icon-wrapper">
                        <img src={attendence} alt="attendence" />
                      </div>
                      <p>Attendance</p>
                    </a>
                  </div>
                </div> */}
                {/* <div className="col-md-2 col-sm-2 inBlock">
                  <div className="single-menu-item">
                    <a href="/leaves">
                      <div className="module-icon-wrapper">
                        <img src={task} alt="biometrick" />
                      </div>
                      <p>Test</p>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="clearfix" />
          </nav>
          <div className="responsive_modal">
            <div id="pulse-modal" className="modal fade topRight" tabIndex={-1}>
              <form
                id="pulse_form"
                action="/dashboard/pulserecord"
                method="post"
              >
                <input
                  type="hidden"
                  defaultValue="5a0b67864fa31e07dfc8a3e1f704f92ac5217c04"
                  name="pbqBeYWPUn"
                />
                <input
                  type="hidden"
                  id="emoji_clicked"
                  name="emoji_clicked"
                  defaultValue=""
                />
                <input
                  type="hidden"
                  id="pulse_question"
                  name="pulse_question"
                  defaultValue="How are you feeling at work today?"
                />
                <div className="modal-dialog mobileWidth-x">
                  <div className="modal-content">
                    <div className="modal-body">
                      <h1 className="title-1 base-1 m-0">
                        How are you feeling at work today?
                      </h1>
                      <ul className="emotion-list list-inline mtb-40">
                        <li className="emotion_clicked emotion1" id={1} />
                        <li className="emotion_clicked emotion2" id={2} />
                        <li className="emotion_clicked emotion3" id={3} />
                        <li className="emotion_clicked emotion4" id={4} />
                        <li className="emotion_clicked emotion5" id={5} />
                      </ul>
                      {/* <input type="text" placeholder="Why"> */}
                      <div className="db-float-input-group">
                        <input
                          className="db-input db-float-input feeling-work-why"
                          type="text"
                          name="pulse_reason"
                          required=""
                        />
                        <span className="bar" />
                        <label className="db-form-label">Why</label>
                      </div>
                      <div className="pt-24 switch-right-lable-left">
                        <span className="caption base-4 pr-4">
                          Post as anonymous
                        </span>
                        <div className="db-switche">
                          <input
                            type="checkbox"
                            name="is_anonymous"
                            id="switch2"
                          />
                          <label htmlFor="switch2" />
                        </div>
                      </div>
                      <div className="action-btns mt-32">
                        <button
                          className="btn btn-secondary ripple db-btn plr-32 mr-12 skip_pulse"
                          data-bs-dismiss="modal"
                        >
                          SKIP
                        </button>
                        <button
                          className="btn btn-primary ripple db-btn plr-32 submit_pulse_btn"
                          id="plus-status-btn"
                          disabled=""
                        >
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/*Outer Shell*/}
        </div>
      </div>
    </>
  );
};

export const analyticsLoader = async () => {
  let analytics = await commonRequest(METHODS.get, "/analytics/all");
  return { analytics };
};
