import React, { useState } from "react";
import Resource from "../../api/resource";
import {
  redirect,
  useLoaderData,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom";
import { Modal } from "react-bootstrap";
import { commonRequest, METHODS } from "../../api/common";

const onlyHRStates = ["Accepted", "Rejected", "OnHold"];

const onlyManager = ["Reverted"];

export const requestStates = [
  "Created",
  "Accepted",
  "Rejected",
  "OnHold",
  "Reverted",
];

const ViewLeave = () => {
  const { user } = useRouteLoaderData("main");

  const { leave } = useLoaderData();
  const navigate = useNavigate();

  const [initialState, setInitialState] = useState(leave[0].state);

  const [request, setRequest] = useState(leave[0]);
  const [show, setShow] = useState(false);

  const fromDate = new Date(request.from);
  const toDate = new Date(request.to);

  const diffMilliseconds = Math.abs(toDate - fromDate);
  const diffMinutes = Math.floor(diffMilliseconds / (1000 * 60));
  const diffHours = Math.floor(diffMinutes / 60);
  const remainingMinutes = diffMinutes % 60;
  const diffDays = Math.floor(diffHours / 24);
  const remainingHours = diffHours % 24;

  const noOfDays = `${diffDays} Days ${remainingHours} Hours ${remainingMinutes} Mins`;

  const handleClose = () => {
    setShow(false);
    navigate("/home/leaves");
  };
  return (
    <main className="mainCardContainer">
      <div className="row">
        <div className="col-lg-12 bg-white bx-shadow border-radius5 p-4">
          <header className="card-header leave-header ptb-20">
            <p className="card-title employeeTitle">{`Ticket ID: #${request._id}`}</p>
            <div className="update-leave">
              {initialState !== request.state && (
                <button
                  className="cssbuttons-io-button"
                  onClick={async () => {
                    let r = await commonRequest(
                      METHODS.put,
                      `/requests/${request._id}/state`,
                      {
                        state: request.state,
                      }
                    );
                    setShow(true);
                    setInitialState(request.state);
                    setTimeout(() => {
                      setShow(false);
                    }, 3000);
                  }}
                >
                  Update
                </button>
              )}
            </div>
          </header>
          <div className="row">
            <div className="col-lg-6 leave-flex">
              <h5>Leave Description</h5>
              <p className="desc-font leave-card pb-2">{request.message}</p>
              <>
                <h5 className="pt-2 mb-0">Leave Days</h5>
                <div className="leave-card">
                  <div className="row leave-desc">
                    <div className="col-lg-4">
                      <h5>No of Days:</h5>
                    </div>
                    <div className="col-lg-8">
                      <h5>{noOfDays}</h5>
                    </div>
                  </div>
                  <div className="row leave-desc">
                    <div className="col-lg-4">
                      <h5>Leave Starts On:</h5>
                    </div>
                    <div className="col-lg-6">
                      <h5>
                        {new Date(request.from).toLocaleString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        })}
                      </h5>
                    </div>
                  </div>
                  <div className="row leave-desc">
                    <div className="col-lg-4">
                      <h5>Leave Ends On:</h5>
                    </div>
                    <div className="col-lg-6">
                      <h5>
                        {new Date(request.to).toLocaleString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        })}
                      </h5>
                    </div>
                  </div>
                  <div className="row leave-desc">
                    <div className="col-lg-4 col-md-5">
                      <h5>Leave Applied On:</h5>
                    </div>
                    <div className="col-lg-6 col-md-8">
                      <h5>
                        {new Date(request.create_at * 1000).toLocaleString(
                          "en-US",
                          {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                          }
                        )}
                      </h5>
                    </div>
                  </div>
                </div>
              </>
            </div>
            <div className="col-lg-6">
              <h5 className="pb-2">Leave Status :- {initialState}</h5>
              <select
                className="form-control"
                onChange={(e) => {
                  setRequest({ ...request, state: e.target.value });
                }}
              >
                <option selected disabled>
                  Leave Status
                </option>
                {(user.role === "HR" ? onlyHRStates : onlyManager).map(
                  (state) => (
                    <option value={state} id={state}>
                      {state}
                    </option>
                  )
                )}
              </select>

              {request.for && (
                <div className="p-2">
                  <h5 className="pt-4 pb-2">Leave for</h5>
                  <div className="leave-card">
                    <div className="row leave-desc">
                      <div className="col-lg-4">
                        <h5>Name:</h5>
                      </div>
                      <div className="col-lg-8">
                        <h5>{request.for.name}</h5>
                      </div>
                    </div>
                    <div className="row leave-desc">
                      <div className="col-lg-4">
                        <h5>Designation:</h5>
                      </div>
                      <div className="col-lg-8">
                        <h5>{request.for.designation}</h5>
                      </div>
                    </div>
                    <div className="row leave-desc">
                      <div className="col-lg-4">
                        <h5>Deptartment:</h5>
                      </div>
                      <div className="col-lg-8">
                        <h5>{request.for.department}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {request.created_by && (
                <div className="p-2">
                  <h5 className="pt-4 pb-2">Leave Raised By</h5>
                  <div className="leave-card">
                    <div className="row leave-desc">
                      <div className="col-lg-4">
                        <h5>Name:</h5>
                      </div>
                      <div className="col-lg-8">
                        <h5>{request.created_by.name}</h5>
                      </div>
                    </div>
                    <div className="row leave-desc">
                      <div className="col-lg-4">
                        <h5>Designation:</h5>
                      </div>
                      <div className="col-lg-8">
                        <h5>{request.created_by.designation}</h5>
                      </div>
                    </div>
                    <div className="row leave-desc">
                      <div className="col-lg-4">
                        <h5>Deptartment:</h5>
                      </div>
                      <div className="col-lg-8">
                        <h5>{request.created_by.department}</h5>
                      </div>
                    </div>
                    <div className="row leave-desc">
                      <div className="col-lg-4">
                        <h5>Branch:</h5>
                      </div>
                      <div className="col-lg-8">
                        <h5>{request.created_by.branch[0].name}</h5>
                      </div>
                    </div>
                    <div className="row leave-desc">
                      <div className="col-lg-4">
                        <h5>City:</h5>
                      </div>
                      <div className="col-lg-8">
                        <h5>{request.created_by.branch[0].city}</h5>
                      </div>
                    </div>
                    <div className="row leave-desc">
                      <div className="col-lg-4">
                        <h5>State:</h5>
                      </div>
                      <div className="col-lg-4">
                        <h5>{request.created_by.branch[0].state}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className="modal-suc"
      >
        <Modal.Body className="bg-none">
          <div className="cards">
            <article className="information card-pop-branch">
              <div className="branch-modal-head">
                <></>
                <i
                  className="bx bx-message-square-x bx-icon-branch-close"
                  onClick={handleClose}
                ></i>
              </div>
              <div>
                <h3 className="modal-title-branch">
                  Leave has been updated successfully.
                </h3>
              </div>
              <div className="modal-btns">
                <button className="btn-delete" onClick={handleClose}>
                  Close
                </button>
                {/* <button
                    className="btn-delete"
                    id=""
                    // onClick={deleteModalHandler}
                  >
                    Delete
                  </button> */}
              </div>
              <dl className="details">
                <div></div>
              </dl>
            </article>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export const LeaveLoader = async ({ params }) => {
  const requestId = params.requestId;

  try {
    let request = await Resource("requests").get(requestId);

    return { leave: request };
  } catch (e) {
    if (e.response.status === 200) {
      return redirect("/login");
    }
  }
  return redirect("/login");
};
export default ViewLeave;
