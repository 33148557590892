import React, { useState, useEffect } from "react";
import { Link, useNavigate, useRouteLoaderData } from "react-router-dom";
import maximize from "../../assets/maximize.svg";
import Logo from "../../assets/logowhite.png";
import setting from "../../assets/settings.svg";

// import Logo from "../../assets/SBGroup.png";
import { clearAccessToken } from "../../api/common";

export const Sidebar = () => {
  const { user, analytics } = useRouteLoaderData("main");

  // const Logo = analytics.theme.logo

  const [mode, setMode] = useState("light");
  const [showSidebar, setShowSidebar] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleSubMenuOpen = () => {
    setIsOpen(!isOpen);
  };
  const handleFullscreenToggle = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setIsFullscreen(false);
    } else {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    }
  };
  const navigate = useNavigate();

  const handleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  return (
    <>
      {/* header section starts */}
      <div
        className="main-header side-header sticky bgLogo"
        style={{ marginBottom: "-65.3333px" }}
      >
        <div className="container-fluid main-container">
          <div className="main-header-left sidemenu" onClick={handleSidebar}>
            <a
              className="main-header-menu-icon"
              href="#"
              data-bs-toggle="sidebar"
              id="mainSidebarToggle"
            >
              <span />
            </a>
          </div>
          <div className="main-header-left horizontal">
            <Link className="main-logo" to="/">
              <img
                src={Logo}
                className="desktop-logo desktop-logo-dark"
                alt="dashleadlogo"
              />
              <img
                src={Logo}
                className="desktop-logo theme-logo"
                alt="dashleadlogo"
              />
            </Link>
          </div>
          <div className="main-header-right">
            <button
              className="navbar-toggler navresponsive-toggler d-lg-none ms-auto collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent-4"
              aria-controls="navbarSupportedContent-4"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon fe fe-more-vertical" />
            </button>
          </div>

          {/* left header  */}
          <div className="navbar navbar-expand-lg navbar-collapse responsive-navbar p-0">
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent-4"
            >
              <ul className="nav nav-item header-icons navbar-nav-right ms-auto">
                <li className="dropdown" onClick={handleFullscreenToggle}>
                  <a className="nav-link icon full-screen-link" href="#">
                    {/* <img src={maximize} alt=" testpublic" /> */}
                    <i class='bx bx-laptop'></i>
                  </a>
                </li>
                {/* FULL SCREEN */}

                <li className="dropdown main-profile-menu position-relative profile-container">
                  <a
                    className="main-img-user"
                    href="#"
                    data-bs-toggle="dropdown"
                  
                  >
                  
                  <i class='bx bx-user' ></i>
                  </a>

                  
                    <div className="dropdown-menu showProfile profile-options">
                      
                      <a
                        className="dropdown-item border-top text-wrap"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/home/profile");
                        }}
                      >
                        <i className="bx bx-user"></i>&nbsp; My Profile
                      </a>
                      
                      <a
                        className="dropdown-item text-wrap"
                        onClick={(e) => {
                          e.preventDefault();
                          clearAccessToken();
                          navigate("/login");
                        }}
                      >
                        <i className="bx bx-power-off"></i>&nbsp; Sign Out
                      </a>
                    </div>
                  
                </li>

                <li>
                  {/* toogle dark and light button */}
                  {/* <div className="pt-2">
                      <label tabIndex="0" className="switch">
                        <input type="checkbox" onClick={toggleMode} />
                        <span className="slider"></span>
                        <span className="icon sun">🌞</span>
                        <span className="icon moon">🌜</span>
                      </label>
                    </div>
                    {/* toogle dark and light button */}
                </li>

                {/* <li className="dropdown main-profile-menu">
                    <img
                      src={setting}
                      alt="setting icon"
                      className="nav-link icon"
                    />
                  </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="jumps-prevent" style={{ paddingTop: "65.3333px" }} />
      {/* header section ends */}
      {/* sidebar starts */}
      <div className="sticky">
        <aside
          className={`app-sidebar ps horizontal-main ps--active-y ${
            showSidebar ? "sidebar open" : "sidebar"
          }`}
        >
          <div className="app-sidebar__header bgLogo">
            <Link className="main-logo" to="/">
              <img
                src={Logo}
                className="desktop-logo desktop-logo-dark"
                alt="dashleadlogo"
              />
              <img src={Logo} className="desktop-logo" alt="dashleadlogo" />
              <img
                src={Logo}
                className="mobile-logo mobile-logo-dark"
                alt="dashleadlogo"
              />
              <img
                src="../assets/img/brand/favicon1.png"
                className="mobile-logo"
                alt="dashleadlogo"
              />
            </Link>
          </div>
          <div className="main-sidemenu mt-90">
            <div className="slide-left disabled d-none" id="slide-left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#7b8191"
                width={24}
                height={24}
                viewBox="0 0 24 24"
              >
                <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
              </svg>
            </div>
            <ul
              className="side-menu"
              style={{ marginLeft: "0px", marginRight: "0px" }}
            >
              <li className="slide">
                <Link
                  to="/home"
                  className="side-menu__item"
                  data-bs-toggle="slide"
                >
                  <span className="side-menu__label">
                    <i className="bx bx-home"></i> &nbsp;Dashboard
                  </span>
                  <i className="bx bx-chevron-right"></i>
                </Link>
              </li>
              {user.role === "HR" && (
                <li className="slide">
                  <Link
                    to="branches"
                    className="side-menu__item"
                    data-bs-toggle="slide"
                  >
                    <span className="side-menu__label">
                      <i className="bx bxs-business"></i> &nbsp;Branches
                    </span>
                    <i className="bx bx-chevron-right"></i>
                  </Link>
                </li>
              )}
              <li className="slide">
                <Link
                  to="employees/new"
                  className="side-menu__item"
                  data-bs-toggle="slide"
                >
                  <span className="side-menu__label">
                    <i className="bx bx-user"></i> &nbsp;Add Employee
                  </span>
                  <i className="bx bx-chevron-right"></i>
                </Link>
              </li>
              <li className="slide">
                <Link
                  to="employees"
                  className="side-menu__item"
                  data-bs-toggle="slide"
                >
                  <span className="side-menu__label">
                    <i className="bx bx-user-pin"></i> &nbsp;Employee Details
                  </span>
                  <i className="bx bx-chevron-right"></i>
                </Link>
              </li>
              <li className="slide">
                <Link
                  to="track"
                  className="side-menu__item"
                  data-bs-toggle="slide"
                >
                  <span className="side-menu__label">
                    <i className="bx bx-bullseye"></i> &nbsp;Employee Monitoring
                  </span>
                  <i className="bx bx-chevron-right"></i>
                </Link>
              </li>
              <li className="slide">
                <Link
                  to="leaves"
                  className="side-menu__item"
                  data-bs-toggle="slide"
                >
                  <span className="side-menu__label">
                    <i className="bx bx-party"></i> &nbsp;Leave
                  </span>
                  <i className="bx bx-chevron-right"></i>
                </Link>
              </li>
              <li className="slide d-lg-none d-md-none d-sm-block d-xs-block">
              {/* <a
                        className="dropdown-item text-wrap"
                        onClick={(e) => {
                          e.preventDefault();
                          clearAccessToken();
                          navigate("/login");
                        }}
                      >
                        <i className="bx bx-power-off"></i>&nbsp; Sign Out
                      </a> */}
                <Link
                  
                  className="side-menu__item "
                  data-bs-toggle="slide"
                  onClick={(e) => {
                    e.preventDefault();
                    clearAccessToken();
                    navigate("/login");
                  }}
                >
                  <span className="side-menu__label">
                  <i className="bx bx-power-off"></i>&nbsp;Log Out
                  </span>
                   
                </Link>
              </li>
            </ul>
          </div>
        </aside>
      </div>
      {/* sidebar ends */}
      {/* footer bar */}

      <div className="main-footer text-center">
        <div>
          <div className="row">
            <div className="col-md-12">
              <span className="mbFooter">
                Copyright © 2023&nbsp;
                <a href="#" className="text-primary">
                  HR Software
                </a>
                . Designed with{" "}
                <span className="bx bx-donate-heart text-danger" />
                &nbsp;by&nbsp;
                <a href="https://www.techsaadhu.com/" className="text-primary">
                  Techsaadhu&nbsp;
                </a>
                All rights reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
