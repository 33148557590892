import React, { useContext } from "react";
import "../../styles/admin.css";
import "../../styles/adminstyle.css";

import Header from "./header"; 
import Footer from "./footer";
import { Outlet } from "react-router-dom";
import { Loading, useLoading } from "../../components/util/loading";


export const Admin = () => {
  const loading = useLoading()
 
  return (
    <>
   <Header/>
      {loading? <Loading/> :<Outlet />}
      {/* /container */}
     
    <Footer/>
     
    </>
  );
};
export default Admin;
