import React from "react";
import { useState } from "react";
import { request, METHODS, setAccessToken } from "../../api/common";
import { ButtonLoading } from "../util/loading";

const Reset = () => {
  const [resetmail, setResetMail] = useState("");
  const [validUser, setValidUser] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let response = await request(
        METHODS.post,
        "/v1",
        `/users/${resetmail}/request_reset`,
        {}
      );
      setValidUser(true);

      //
    } catch (e) {
      if (e.response.status === 400) {
        setValidUser(false);
      }
    }

    setTimeout(() => setValidUser(undefined), 5000);

    setLoading(false);
  };
  return (
    <>
      <div className="login-content d-flex flex-column  overflow-auto">
        <div className="cardLogin">
          <div className="tools">
            <div className="circle">
              <span className="red box"></span>
            </div>
            <div className="circle">
              <span className="yellow box"></span>
            </div>
            <div className="circle">
              <span className="greenLOgin box"></span>
            </div>
          </div>
          <div className="card__content">
            <div className="screen-1">
              <img
                src="https://sbventures.in/wp-content/uploads/2021/08/Trimmed-Logo.png"
                alt=""
                srcset=""
              />
            </div>
            <div className="loginTitle">
              <h3>Reset Password</h3>
              <p>
                Enter the email address associated with your account and we'll
                send you a link to reset your password.
              </p>
            </div>
            <section className="formField">
              <form onSubmit={handleSubmit}>
                <main>
                  <div className="input-groupLogin">
                    <input
                      required=""
                      type="email"
                      autocomplete="off"
                      className="inputLogin"
                      name="email"
                      onChange={(e) => setResetMail(e.target.value)}
                    />
                    <label className="user-label">Email</label>
                    {validUser === true ? (
                      <p className="LogoColorgreen mt-2">
                        <i className="bx bx-shield-x"></i> Link sent
                        successfully check your email
                      </p>
                    ) : validUser === false ? (
                      <p className="LogoColorRed mt-2">
                        <i className="bx bx-shield-x"></i> Invalid user
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="btnPart">
                    <button type="submit" className="LoginBtn">
                      {loading ? <ButtonLoading /> : "Continue"}
                    </button>
                  </div>
                </main>
              </form>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reset;
