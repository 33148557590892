import FixedHeaderStory from "react-data-table-component";
import React, { useEffect, useMemo, useRef, useState } from "react";
import track from "../../assets/monitoring.png";
import { Link } from "react-router-dom";
import { matchSorter } from "match-sorter";
import DatePicker from "react-datepicker";
import MultiDatePicker from "react-multi-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import { useLoaderData, useRouteLoaderData } from "react-router";
import { commonRequest, METHODS } from "../../api/common";
import { formatDate, formatTime } from "../home/home";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import { utils, writeFileXLSX } from "xlsx";
import Pdf from "../pdf";
import {saveAs} from 'file-saver';
import { useReactToPrint } from "react-to-print";
import {PDFDownloadLink, PDFViewer} from "@react-pdf/renderer";

const getLastDateTimeOfMonth = (year, month) => {
  // Create a new Date object for the next month's first day
  const nextMonthDate = new Date(year, month + 1, 1);

  // Subtract 1 millisecond from the next month's first day to get the last second of the current month
  return new Date(nextMonthDate.getTime() - 1);
};

const getFirstOfMonth = () => {
  const date = new Date();
  date.setDate(1);
  return date;
};

let startColumns = [
  {
    name: "Employee Id",
    selector: (row) => row.empNo,
    wrap: true,
    width: "150px",
  },
  { name: "Name", selector: (row) => row.name, wrap: true, width: "150px" },
];

const iterateOverDates = (startDate, endDate) => {
  const dates = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

const dateString = (date) =>
  `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

function getTotalDaysInMonth(date) {
  // Get the year and month from the provided date object
  const year = date.getFullYear();
  const month = date.getMonth();

  // Create a new Date object with the year and month
  const firstDayOfMonth = new Date(Date.UTC(year, month, 1));

  // Move to the next month by setting the month to the next month
  firstDayOfMonth.setUTCMonth(firstDayOfMonth.getUTCMonth() + 1);

  // Subtract one day to get the last day of the current month
  firstDayOfMonth.setUTCDate(firstDayOfMonth.getUTCDate() - 1);

  // Retrieve and return the day component, which represents the total number of days in the month
  return firstDayOfMonth.getUTCDate();
}

const doCalc = (emp, date, cellType, minimumLoginTime, textOnly = false) => {
  let timings = emp.loginsByDates;
  const year = date.getFullYear();
  if (timings[year]) {
    const month = date.getMonth();
    if (timings[year][month]) {
      const day = date.getDate();

      if (timings[year][month]) {
        let times = timings[year][month][day];

        if (!times) {
          return cellType === "pa" ? "A (0 h)" : "";
        }

        let logins = [];
        let logouts = [];
        times.forEach((time, index) => {
          let f;
          if (index % 2 === 0) {
            f = logins;
          } else {
            f = logouts;
          }
          f.push(time);
        });

        let totalLoginTime = 0;

        for (let i = 0; i < logins.length; i++) {
          let logout = logouts[i];

          if (!logout) {
            continue; // Don't calculate the logout here
          }

          totalLoginTime += logout.getTime() - logins[i].getTime();
        }

        let data;
        let firstLogin = logins[0];

        switch (cellType) {
          case "lt":
            data = formatTime(totalLoginTime);
            break;
          case "ll":
            data = logins.map((login, index) => {
              let logout = logouts[index];
              let login_time = formatDate(login);
              let logout_time = logout ? formatDate(logout) : logout;
              return textOnly ? (
                `${login_time} - ${logout_time}`
              ) : (
                <>
                  {login_time} - {logout_time}
                  <br />
                </>
              );
            });

            if (textOnly) {
              data = data.join(", ");
            }
            break;
          case "pa":
            data = `${
              totalLoginTime > minimumLoginTime ? "P" : "A"
            } (${formatTime(totalLoginTime)})`;
        }

        return [data, firstLogin];
      }
    }
  }
};

const getSecInDay = (d) => {
  let date = new Date(d);
  return date.getSeconds() + date.getMinutes() * 60 + date.getHours() * 60 * 60;
};

export const Tracing = () => {
  const { analytics, user } = useRouteLoaderData("main");
  const { branches } = useLoaderData();

  const pdf = useRef(null);

  const [selectedBranch, setSelectedBranch] = useState(undefined);
  const [filterText, setFilterText] = React.useState("");
  const [startDate, setStartDate] = useState(getFirstOfMonth());
  const [attData, setAttData] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [cellType, setCellType] = useState("pa");
  const [mlt, setMlt] = useState(dayjs(2 * 60 * 60 * 1000 + 60 * 60 * 1000));

  const [lateTime, setLateTime] = useState(
    dayjs(3 * 60 * 60 * 1000 + 60 * 60 * 1000)
  );

  const [excludeDates, setExcludeDates] = useState([]);

  const excludes = useMemo(
    () => (excludeDates || []).map((d) => d.toDate()),
    [excludeDates]
  );

  const excludesStrings = useMemo(
    () => new Set(excludes.map((date) => dateString(date))),
    [excludes]
  );

  const minimumLoginTime = useMemo(() => {
    return mlt !== null
      ? mlt.hour() * 60 * 60 * 1000 + mlt.minute() * 60 * 1000
      : 0;
  }, [mlt]);

  const lt = useMemo(
    () =>
      lateTime.hour() * 60 * 60 + lateTime.minute() * 60 + lateTime.second(),
    [lateTime]
  );

  let endDate = useMemo(
    () => getLastDateTimeOfMonth(startDate.getFullYear(), startDate.getMonth()),
    [startDate]
  );

  let totalDays = useMemo(
    () => getTotalDaysInMonth(startDate) - excludes.length,
    [startDate, excludes]
  );

  useEffect(() => {
    const g = async () => {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      let response = await commonRequest(
        METHODS.get,
        `/attendance/?${
          selectedBranch ? `branch=${selectedBranch}&` : ""
        }startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
      );

      response.forEach((element) => {
        const loginsByDates = {};

        element.timings = element.timings.map((time) => {
          let date = new Date(time.time["$date"]);

          const year = date.getFullYear();
          if (!loginsByDates[year]) {
            loginsByDates[year] = {};
          }

          const month = date.getMonth();

          if (!loginsByDates[year][month]) {
            loginsByDates[year][month] = {};
          }

          const day = date.getDate();

          if (!loginsByDates[year][month][day]) {
            loginsByDates[year][month][day] = [];
          }

          loginsByDates[year][month][day].push(date);
        });

        element.loginsByDates = loginsByDates;
      });

      setAttData(response);
    };
    g();
  }, [startDate, endDate, selectedBranch]);

  const [newData, columns] = useMemo(() => {
    let newData = attData.map((emp) => {
      emp.cell = {};
      emp.t = totalDays;
      let presents = 0;
      let absents = 0;
      let holidayPresents = 0;
      let lates = 0;

      iterateOverDates(startDate, endDate).forEach((date) => {
        let time = date.getTime();
        let isHoliday = excludesStrings.has(dateString(date));
        let d = doCalc(emp, date, cellType, minimumLoginTime, true);
        if (d) {
          let [result, firstLogin] = d;
          // console.log(firstLogin)
          if (firstLogin && getSecInDay(firstLogin) > lt) {
            lates += 1; // Increase Late s
          }

          emp.cell[date.getDate()] = result;

          let present = /^[P]/.test(result);

          if (present) {
            if (isHoliday) {
              holidayPresents += 1;
            } else {
              presents += 1;
            }
          } else {
            if (!isHoliday) {
              absents += 1;
            }
          }
        }
      });

      emp.p = presents;
      emp.a = absents;
      emp.hp = holidayPresents;
      emp.h = excludeDates.length;
      emp.l = lates;
      return emp;
    });

    let midColumns = iterateOverDates(startDate, endDate).map((date) => {
      let isHoliday = excludesStrings.has(dateString(date));
      return {
        name: date.toLocaleDateString("en-US", {
          weekday: "short",
          day: "numeric",
        }),
        wrap: true,
        width: "200px",
        selector: (emp, textOnly) => {
          if (emp.cell) {
            let result = emp.cell[date.getDate()];

            if (result){
              result = result.replace("undefined", "");

            }

            if (isHoliday) {
              return /^[P]/.test(result) ? result : "H";
            }

            return result;
          }
        },
      };
    });

    const endColumns =
      cellType === "pa"
        ? [
            {
              name: "T",
              selector: (row) => row.t,
              wrap: true,
            },
            {
              name: "P",
              selector: (row) => row.p,
              wrap: true,
            },
            {
              name: "A",
              selector: (row) => row.a,
              wrap: true,
            },
            {
              name: "L",
              selector: (row) => row.l,
              wrap: true,
            },
            {
              name: "H",
              selector: (row) => row.h,
              wrap: true,
            },
            {
              name: "HP",
              selector: (row) => row.hp,
              wrap: true,
            },
          ]
        : [];

    return [newData, [...startColumns, ...midColumns, ...endColumns]];
  }, [
    attData,
    startDate,
    endDate,
    excludeDates,
    cellType,
    minimumLoginTime,
    mlt,
    lateTime,
  ]);

  const filterItems = useMemo(
    () =>
      matchSorter(newData, filterText, {
        keys: ["id", "name", "email", "number", "empNo"],
      }),
    [attData, filterText]
  );

  return (
    <>
      <main className="mainCardContainer">
        <div className="row">
          <div className="col-lg-12 bg-white bx-shadow border-radius5">
            <header className="position-relative pt-20">
              <aside className="row position-relative d-flex ">
                <div className="col-lg-6 col-xs-6 col-sm-6">
                  <img src={track} alt="" srcset="" className="w-5percent" />
                  <span className="eploye_mont">Employee Monitoring</span>
                </div>
                <div className="col-lg-6 col-xs-6 col-sm-6">
                  <div className="search-box top-17m aside-bar">
                    <input
                      className="search-input"
                      type="search"
                      placeholder="Search something.."
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                    <button className="search-btn">
                      <i className="bx bx-search"></i>
                    </button>
                  </div>
                </div>
              </aside>
            </header>
            <header className="row head-extra position-relative pt-20">
              <aside className="position-relative head-d-gap col-lg-3 col-xs-6">
                <div className="position-relative">
                  <label htmlFor="" className="date-label">
                    Month
                  </label>
                  <DatePicker
                    maxDate={new Date()}
                    selected={startDate}
                    selectsStart
                    dateFormat={"MM/yyyy"}
                    showMonthYearPicker
                    value={startDate}
                    onChange={(v) => setStartDate(v)}
                  />

                  {/* <i className='bx bx-calendar calender-icon'></i> */}
                </div>

                {/* <div className="position-relative">
                <label htmlFor="" className="date-label">End Date</label>
                  <DatePicker
                    showIcon
                    selected={endDate}
                    maxDate={new Date()}
                    onChange={(date) => setEndDate(date)}
                  />
                  <i className='bx bx-calendar calender-icon'></i>
                </div> */}
              </aside>
              <aside className="col-lg-2 col-xs-6">
                <div>
                  <div>
                    <label htmlFor="" className="date-label">
                      Exclude Date's
                    </label>
                  </div>

                  <MultiDatePicker
                    className="multiDate"
                    value={excludeDates}
                    onChange={setExcludeDates}
                    multiple
                  />
                </div>
              </aside>
              {user.role === "HR" && (
                <aside className="col-lg-2 col-xs-6">
                  <div>
                    <label htmlFor="" className="date-label">
                      Select Branch
                    </label>
                    <select
                      name=""
                      id=""
                      className="branchFilter"
                      onChange={(e) => setSelectedBranch(e.target.value)}
                    >
                      <option value="">All</option>
                      {branches.map((branch) => (
                        <option
                          value={branch._id}
                          selected={branch._id === selectedBranch}
                        >
                          {branch.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </aside>
              )}

              <aside className="col-lg-2 col-xs-6">
                <div>
                  <label htmlFor="" className="date-label">
                    Type
                  </label>
                  <select
                    name=""
                    id=""
                    className="branchFilter"
                    onChange={(e) => setCellType(e.target.value)}
                  >
                    {/*<option value="lt" selected={"lt" === cellType}>*/}
                    {/*  Login Time*/}
                    {/*</option>*/}
                    <option value="ll" selected={"ll" === cellType}>
                      Login / Logouts
                    </option>
                    <option value="pa" selected={"pa" === cellType}>
                      Present / Absent
                    </option>
                  </select>
                </div>
              </aside>
              {cellType === "pa" && (
                <>
                  <div className="d-grid col-lg-3 col-xs-6">
                    <label htmlFor="" className="date-label">
                      Min Login Time
                    </label>

                    <TimePicker
                      defaultValue={dayjs("00:00", "HH:mm")}
                      format={"HH:mm"}
                      value={mlt}
                      onChange={setMlt}
                    />
                  </div>
                  <div className="d-grid col-lg-3 col-xs-6">
                    <label htmlFor="" className="date-label">
                      Late Time
                    </label>

                    <TimePicker
                      defaultValue={dayjs("00:00", "HH:mm")}
                      format={"HH:mm"}
                      value={lateTime}
                      onChange={setLateTime}
                    />
                  </div>
                </>
              )}

              <div className="d-flex col-lg-3 mt-4 col-xs-6">
                <nav role="navigation" className="primary-navigation m-0">
                  <ul className="mb-0 p-0">
                    <li className="p-0">
                      <Link className="cssbuttons-io-button">
                        <i className="bx bxs-download"></i>&nbsp;Download
                      </Link>
                      <ul className="dropdown">
                        <li>
                          <Link
                            onClick={() => {
                              let rows = [];
                              for (let emp of filterItems) {
                                let row = {};
                                for (let column of columns) {
                                  row[column.name] = column.selector(emp, true);
                                  // console.log(column)
                                }
                                rows.push(row);
                              }

                              let json = utils.json_to_sheet(rows);
                              // console.log(rows, json);

                              const wb = utils.book_new();
                              utils.book_append_sheet(wb, json, "Data");

                              writeFileXLSX(wb, "Export.xlsx");
                            }}
                          >
                            <i className="bx bxs-file-import"></i> Excel
                          </Link>
                        </li>
                        <li>
                          <PDFDownloadLink document={<Pdf
                              pdf={pdf}
                              attData={filterItems}
                              month={startDate.toLocaleString("en-US", {
                                month: "long",
                                year: "numeric",
                              })}
                              branch={branches.find((b) => b._id === selectedBranch)}
                              cellType={cellType}
                              minimumLoginTime={minimumLoginTime}
                              excludes={excludes}
                              columns={columns}
                              analytics={analytics}
                              user={user}
                          />} fileName="report.pdf">
                            {({ blob, url, loading, error }) =>
                                <><i className="bx bxs-file-pdf"></i>{loading? "Loading PDF":"PDF"}</>
                            }
                          </PDFDownloadLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </header>

            <section className="reactDatatable position-relative">
              <FixedHeaderStory
                fixedHeader
                fixedHeaderScrollHeight="500px"
                columns={columns}
                data={filterItems}
                pagination
                paginationPerPage={7}
                paginationResetDefaultPage={resetPaginationToggle}
                persistTableHead
              />
            </section>

            {/* <FixedHeaderStory
              fixedHeader
              fixedHeaderScrollHeight="500px"
              columns={columns}
              data={data}
              pagination
              paginationPerPage={7}
            /> */}
          </div>
        </div>
        <div>
        </div>
      </main>
    </>
  );
};

export default Tracing;
