import React from "react";
import { useState } from "react";
import {
  useSearchParamsj,
  redirect,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import { request, METHODS, setAccessToken } from "../../api/common";
import { ButtonLoading } from "../util/loading";

const ResetPassword = () => {
  let { userId, token } = useLoaderData();
  const [password1, setPassword1] = useState();
  const [confirmpassword, setConfirmPassword] = useState();
  const [pwdMessage, setpwdMessage] = useState(false);
  const [pwdMsg, setPwdMsg] = useState(undefined);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const password = password1 === confirmpassword;

    if (!password) {
      setpwdMessage(true);
      setTimeout(() => setpwdMessage(false), 3000);
      return;
    }
    setLoading(true);

    try {
      let response = await request(
        METHODS.post,
        "/v1",
        `/users/${userId}/reset`,
        {
          password: password1,
          token: token,
        }
      );
      setPwdMsg(true);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (e) {
      if (e.response.status === 400) {
        setPwdMsg(false);
      }
    }

    setLoading(false);
  };

  return (
    <>
      <div className="login-content d-flex flex-column  overflow-auto">
        <div className="cardLogin">
          <div className="tools">
            <div className="circle">
              <span className="red box"></span>
            </div>
            <div className="circle">
              <span className="yellow box"></span>
            </div>
            <div className="circle">
              <span className="greenLOgin box"></span>
            </div>
          </div>
          <div className="card__content">
            <div className="screen-1">
              <img
                src="https://sbventures.in/wp-content/uploads/2021/08/Trimmed-Logo.png"
                alt=""
                srcset=""
              />
            </div>
            <div className="loginTitle">
              <h3>Enter your new password</h3>
              <p>
                Get started with our app, just create an account and enjoy the
                experience.
              </p>
            </div>
            <section className="formField">
              <form onSubmit={handleSubmit}>
                <main>
                  <div className="input-groupLogin">
                    <input
                      required=""
                      type="Password"
                      autocomplete="off"
                      className="inputLogin"
                      name="pwd"
                      onChange={(e) => setPassword1(e.target.value)}
                    />
                    <label className="user-label">Password</label>
                  </div>
                  <div className="input-groupLogin">
                    <input
                      required=""
                      type="Password"
                      autocomplete="off"
                      className="inputLogin"
                      name="confirmPwd"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <label className="user-label">Confirm password</label>
                    {pwdMessage ? (
                      <p className="LogoColorRed mt-2">
                        <i className="bx bx-shield-x"></i> Password doesn't
                        match please check
                      </p>
                    ) : (
                      ""
                    )}
                    {pwdMsg === true ? (
                      <p>Password Reset Successful</p>
                    ) : pwdMsg === false ? (
                      <p className="LogoColorRed mt-2">
                        <i className="bx bx-shield-x"></i> Invalid Link or Link
                        Expired
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="btnPart">
                    <button type="submit" className="LoginBtn">
                      {loading ? <ButtonLoading /> : "Submit"}
                    </button>
                  </div>
                </main>
              </form>
            </section>
          </div>
        </div>
      </div>

      {/* <div className="login-content d-flex flex-column  overflow-auto">
        <div>
          <div className="mt-10 mb-40 w-300 h-50px"></div>
          <div>
            <div>
              <p className="font-weight-semi-bold text-x-large mb-20">
                Change Password
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <div>
                <div className="form-floating">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    id="email"
                    name="pwd"
                    onChange={(e) => setPassword1(e.target.value)}
                  />
                  <input
                    type="password"
                    className="form-control mt-4"
                    placeholder="Confirm Password"
                    id="email"
                    name="confirmPwd"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                {pwdMessage ? <p>Passswords doesn't matched</p> : ""}
                {pwdMsg === true ? (
                  <p>Password Reset Successful</p>
                ) : pwdMsg === false ? (
                  <p>Invalid link or Link Expired</p>
                ) : (
                  <></>
                )}
                <button
                  type="submit"
                  className="btn btn-primary mt-10 border-0 mt-2"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div> */}
    </>
  );
};

export const ResetPasswordLoader = ({ request }) => {
  let url = new URL(request.url);

  let userId = url.searchParams.get("userID");
  let token = url.searchParams.get("token");

  if (!userId || !token) {
    return redirect("/login");
  }

  return { userId, token };
};

export default ResetPassword;
