import { useRouteLoaderData } from "react-router-dom"


export const HrOnly = ({children}) =>{
    const {user} = useRouteLoaderData("main");

    if (user.role === 'HR'){
        return children
    }
    else{
        return <>Not Found</>
    }
}