import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { Loading, useLoading } from "../util/loading";
export const Branchdashboard = () => {
  const loading = useLoading()
  return (
    <>
      {loading? <Loading />:<Outlet />}
    </>
  );
};

export default Branchdashboard;
