import React, { useContext } from "react";

import Sidebar from "../sidebar/sider";
import { Outlet } from "react-router-dom";
import { Loading, useLoading } from "../util/loading";


export const Employeecomponent = () =>{
    const loading = useLoading()
    return(
        <>
         
        {loading? <Loading/> :<Outlet />}
       
        
        </>
    )
}
export default Employeecomponent;