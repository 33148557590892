import { Outlet, useNavigate } from "react-router";
import "../../styles/global.css";
import "../../styles/login.css";
// import login from "../../assets/loginrisk.svg";
import login from "../../assets/Computerlogin.svg";
import { Loading, useLoading } from "../util/loading";
export const LoginIndex = () => {
  const loading = useLoading()
  return (
    <>
      <div className="d-flex">
        <div className="position-relative bg-login w-100-500">
          <img
            src={login}
            alt="logo"
          />
          {/* https://cdn.kekastatic.net/login/v/M146_2023.03.25.1/images/login-image.jpg */}
        </div>
        {loading? <Loading/> :<Outlet />}
      </div>
    </>
  );
};
export default LoginIndex;
