import React, { useState } from "react";
import ReactSwitch from "react-switch";
import check from "../../styles/check.module.css";
import Resource from "../../api/resource";
export const Organization = () => {
  const [checked, setChecked] = useState(true);
  const [color, setColor] = useState("");
  const [organization, setOrganization] = useState({ theme: {} });

  const handleChange = (val) => {
    setChecked(val);
  };

  const handleColorChange = (event) => {
    setColor(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(organization, "organization data");
    try {
      let res = await Resource("organizations").create(organization);
      alert("Successfully Created");
      console.log(res, "response org");
    } catch (e) {
      alert(e.message);
    }
  };

  return (
    <>
      <main className="main-container-org">
        <section className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-8">
            {/* ============================================================= */}

            <div className="card bg-white bx-shadow">
              <div className="card-header">
                <h3 className="card-title m-0">Organization</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname"
                        >
                          Organization Name
                        </label>
                        <input
                          placeholder="name"
                          type="text"
                          value={organization.name}
                          id="exampleInputname"
                          className="form-control"
                          onChange={(e) =>
                            setOrganization({
                              ...organization,
                              name: e.target.value
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname1"
                        >
                          Email
                        </label>
                        <input
                          placeholder="email"
                          type="email"
                          id="exampleInputname1"
                          className="form-control"
                          value={organization.email}
                          onChange={(e) =>
                            setOrganization({
                              ...organization,
                              email: e.target.value
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 equal-form-space">
                      <div>
                        <label
                          className="form-label"
                          htmlFor="exampleInputname"
                        >
                          Logo Url
                        </label>
                        <input
                          placeholder="Logo Url"
                          type="url"
                          id="exampleInputname"
                          className="form-control"
                          value={organization.logo}
                          onChange={(e) =>
                            setOrganization({
                              ...organization,
                              theme: {
                                ...organization.theme,
                                logo: e.target.value
                              }
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 equal-form-space">
                      <aside className="d-flex justify-content-between choose-color">
                        <div>
                          <p>Active</p>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            className={check.tog}
                            value={organization.active}
                            onChange={(e) =>
                              setOrganization({
                                ...organization,
                                active: e.target.checked
                              })
                            }
                          />
                        </div>
                      </aside>
                    </div>
                    <div className="col-lg-6 col-md-6 equal-form-space">
                      <aside className="d-flex justify-content-between choose-color">
                        <div>
                          <label>Primary color:</label>
                        </div>
                        <div>
                          <input
                            type="color"
                            className="color-input"
                            value={organization.primary}
                            onChange={(e) =>
                              setOrganization({
                                ...organization,
                                theme: {
                                  ...organization.theme,
                                  primary: e.target.value
                                }
                              })
                            }
                          />
                        </div>
                      </aside>

                      <div
                        className="color-show bx-shadow"
                        style={{ backgroundColor: color }}
                      >
                        <p className="text-black">{color}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 equal-form-space">
                      <aside className="d-flex justify-content-between choose-color">
                        <div>
                          <label>Secondary color:</label>
                        </div>
                        <div>
                          <input
                            type="color"
                            className="color-input"
                            value={organization.secondary}
                            onChange={(e) =>
                              setOrganization({
                                ...organization,
                                theme: {
                                  ...organization.theme,
                                  secondary: e.target.value
                                }
                              })
                            }
                          />
                        </div>
                      </aside>

                      <div
                        className="color-show bx-shadow"
                        style={{ backgroundColor: color }}
                      >
                        <p className="text-black">{color}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 equal-form-space">
                      <aside className="d-flex justify-content-between choose-color">
                        <div>
                          <label>Accent color:</label>
                        </div>
                        <div>
                          <input
                            type="color"
                            className="color-input"
                            value={organization.accent}
                            onChange={(e) =>
                              setOrganization({
                                ...organization,
                                theme: {
                                  ...organization.theme,
                                  accent: e.target.value
                                }
                              })
                            }
                          />
                        </div>
                      </aside>

                      <div
                        className="color-show bx-shadow"
                        style={{ backgroundColor: color }}
                      >
                        <p className="text-black">{color}</p>
                      </div>
                    </div>
                    <div className="col-lg-12 d-flex justify-content-center mtb-20">
                      <div>
                        <button className="cssbuttons-io-button" type="submit">
                          {" "}
                          Submit
                          <div className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path
                                fill="white"
                                d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                              ></path>
                            </svg>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-lg-1"></div>
        </section>
      </main>
    </>
  );
};
export default Organization;
